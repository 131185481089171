import { HealthcheckList, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { PageBody } from '../../routes/PageBody'
import { useOrg } from '../organization/useOrg'
import { BillingHealthcheck } from './BillingHealthcheck'

export const BillingPage = () => {
  const org = useOrg()
  if (!org) return null
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Billing</Typography>
      <HealthcheckList>
        <BillingHealthcheck org={org} />
      </HealthcheckList>
    </PageBody>
  )
}
