import { TextField } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { QuestionContainer, QuestionSubtext, QuestionText } from '../surveyBlocks'
import { LongTextQuestion } from '../types'

type LongTextConfigProps = {
  questionText: string
  questionSubtext?: string
  questionId: string
  placeholder?: string
  required: boolean
}
export const makeLongTextConfig = ({
  questionText,
  questionSubtext,
  questionId,
  placeholder,
  required,
}: LongTextConfigProps) => {
  const question = {
    questionText,
    questionSubtext,
    questionId,
    required,
    longText: true,
    getFieldValue: (answer) => answer || '',
    getAnswer: (values) => values[questionId] || '',
  } as const satisfies LongTextQuestion
  return {
    ...question,
    Input: <T extends FieldValues>({ register }: { register: UseFormRegister<T> }) => (
      <LongTextQuestionDisplay
        question={question}
        register={register}
        placeholder={placeholder}
        required={required}
      />
    ),
  }
}

type LongTextQuestionDisplayProps<T extends FieldValues> = {
  question: LongTextQuestion
  register: UseFormRegister<T>
  placeholder?: string
  required?: boolean
  error?: boolean
}
const LongTextQuestionDisplay = <T extends FieldValues>({
  question,
  register,
  placeholder,
  required,
  error,
}: LongTextQuestionDisplayProps<T>) => {
  return (
    <QuestionContainer>
      {question.questionText && <QuestionText>{question.questionText}</QuestionText>}
      {question.questionSubtext && <QuestionSubtext>{question.questionSubtext}</QuestionSubtext>}
      <TextField
        multiline
        rows={4}
        fullWidth
        {...register(question.questionId as Path<T>)}
        placeholder={placeholder}
        required={required}
        sx={{ mb: 1 }}
        helperText={required && 'required'}
        error={error}
      />
    </QuestionContainer>
  )
}
