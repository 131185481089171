import {
  Box,
  Grid,
  Paper,
  Typography,
  FormLabel,
  Button,
  TextFieldController,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { PageBody } from '../../routes/PageBody'
import { dateFormat } from '../../../utils/formatters/dateFormat'
import DownloadIcon from '@mui/icons-material/Download'

type ReportId = 'finance' | 'invoices' | 'payroll'

interface ReportType {
  id: ReportId
  title: string
  description: string
  downloadUrl: string
}

const REPORT_TYPES: ReadonlyArray<ReportType> = [
  {
    id: 'finance',
    title: 'Finance Summary Report',
    description: 'Summary of invoices, charges, billing discounts and credits, and Alice Card transfers.',
    downloadUrl: '/employer/organizations/download_financial_report',
  },
  {
    id: 'invoices',
    title: 'Invoices Report',
    description: 'Summary of all invoices and charges.',
    downloadUrl: '/employer/organizations/download_invoices_report',
  },
  {
    id: 'payroll',
    title: 'Pay Periods Report',
    description: 'Summary of all pay period deductions and reimbursements, by employee and benefit type.',
    downloadUrl: '/employer/organizations/download_pay_periods_report',
  },
] as const

interface FormValues {
  startDate: string
  endDate: string
  reportType: ReportId | null
}

interface ReportTypeSelectorProps {
  selectedType: ReportId | null
  onSelect: (type: ReportId) => void
}

const ReportTypeSelector = ({ selectedType, onSelect }: ReportTypeSelectorProps) => {
  return (
    <Grid container spacing={2}>
      {REPORT_TYPES.map((type) => (
        <Grid item xs={12} sm={4} key={type.id}>
          <Paper
            sx={(theme) => ({
              padding: theme.spacing(2),
              cursor: 'pointer',
              border: `1px solid ${selectedType === type.id ? theme.palette.primary.main : theme.palette.grey[200]}`,
              backgroundColor:
                selectedType === type.id ? theme.palette.primary.light : theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                '& .report-title': {
                  color: theme.palette.common.white,
                },
              },
            })}
            onClick={() => onSelect(type.id)}
          >
            <Typography
              className="report-title"
              variant="subtitle1"
              gutterBottom
              sx={(theme) => ({
                color: selectedType === type.id ? theme.palette.common.white : 'inherit',
              })}
            >
              {type.title}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

const ReportDescription = ({ selectedType }: { selectedType: ReportType['id'] | null }) => {
  const selectedReport = REPORT_TYPES.find((type) => type.id === selectedType)

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
      })}
    >
      {selectedReport ? (
        <Typography variant="body1">{selectedReport.description}</Typography>
      ) : (
        <Typography variant="body1" color="text.secondary" align="center">
          Choose a report above to get started
        </Typography>
      )}
    </Box>
  )
}

const DateRangeForm = ({
  control,
  selectedType,
}: {
  control: ReturnType<typeof useForm<FormValues>>['control']
  selectedType: ReportId | null
}) => {
  const today = React.useMemo(() => dateFormat.inputVal(new Date()), [])

  if (!selectedType) return null

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.shape.borderRadius,
      })}
    >
      <Typography variant="subtitle1" gutterBottom>
        Date Range
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormLabel htmlFor="startDate">Start Date</FormLabel>
          <TextFieldController
            name="startDate"
            type="date"
            fullWidth
            required
            control={control}
            inputProps={{ max: today }}
            rules={{ required: 'Start date is required' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel htmlFor="endDate">End Date</FormLabel>
          <TextFieldController
            name="endDate"
            type="date"
            fullWidth
            required
            control={control}
            inputProps={{ max: today }}
            rules={{
              required: 'End date is required',
              validate: (value, formValues) => {
                if (!formValues.startDate || !value) return true
                return (
                  new Date(value) >= new Date(formValues.startDate) || 'End date must be after start date'
                )
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const ReportHeader = ({
  selectedType,
  disabled,
  startDate,
  endDate,
}: {
  selectedType: ReportType['id'] | null
  disabled?: boolean
  startDate: string
  endDate: string
}) => {
  const selectedReport = REPORT_TYPES.find((type) => type.id === selectedType)

  if (!selectedReport) return null

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      })}
    >
      <Typography variant="subtitle1" color="text.secondary">
        {selectedReport.title} ({startDate} - {endDate})
      </Typography>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={disabled}
        startIcon={<DownloadIcon />}
      >
        Download Report
      </Button>
    </Box>
  )
}

const handleReportDownload = (values: FormValues) => {
  if (!values.reportType) return

  const selectedReport = REPORT_TYPES.find((type) => type.id === values.reportType)
  if (!selectedReport) return

  const apiBase = process.env.ALICE_ORIGIN
  const downloadUrl = `${apiBase}${selectedReport.downloadUrl}?start_date=${values.startDate}&end_date=${values.endDate}`
  window.location.href = downloadUrl
}

export const ReportBuilderPage = () => {
  const today = React.useMemo(() => dateFormat.inputVal(new Date()), [])
  const startOfYear = React.useMemo(() => {
    const date = new Date()
    date.setMonth(0)
    date.setDate(1)
    return dateFormat.inputVal(date)
  }, [])

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    defaultValues: {
      startDate: startOfYear,
      endDate: today,
      reportType: null,
    },
    mode: 'onChange',
  })
  const selectedType = watch('reportType')
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const setSelectedType = (type: ReportType['id']) => {
    setValue('reportType', type)
  }

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Report Builder
      </Typography>

      <form onSubmit={handleSubmit(handleReportDownload)}>
        <ReportTypeSelector selectedType={selectedType} onSelect={setSelectedType} />
        <ReportDescription selectedType={selectedType} />
        <DateRangeForm control={control} selectedType={selectedType} />
        <ReportHeader
          selectedType={selectedType}
          disabled={!isValid}
          startDate={startDate}
          endDate={endDate}
        />
      </form>
    </PageBody>
  )
}
