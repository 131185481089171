import * as React from 'react'
import { Navigate, RouteObject } from 'react-router'
import { FinchAuthGateway } from '../externalServices/finch/FinchAuthGateway'
import { CompanyProfilePage } from '../organization/onboarding/survey/companyProfile/CompanyProfilePage'
import { NotFound } from '../routes/NotFound'
import { AdminProfilePage } from './adminProfile/AdminProfilePage'
import { AliceCardProgramPage } from './aliceCardProgram/AliceCardProgramPage'
import { EnableConnectComponents } from './aliceCardProgram/EnableConnectComponents'
import { FundingAccountPage } from './aliceCardProgram/FundingAccountPage'
import { FundingAccountSetupPage } from './aliceCardProgram/setup/FundingAccountSetupPage'
import { BillingPage } from './billing/BillingPage'
import { DocumentsPage } from './documents/DocumentsPage'
import { EmployeeListPage } from './employeeList/EmployeeListPage'
import { EmployerHomepage } from './employer/EmployerHomepage'
import { OrgHomepage } from './homepage/OrgHomepage'
import { OrderFormPage } from './orderForm/OrderFormPage'
import { OrgDashboardRouteChrome } from './OrgDashboardRouteChrome'
import { OrgRootRoute } from './OrgRootRoute'
import { PayPeriodsPage } from './payPeriods/PayPeriodsPage'
import { PayrollPage } from './payroll/PayrollPage'
import { PayrollReportsPage } from './payrollReports/PayrollReportsPage'
import { ReportBuilderPage } from './reports/ReportBuilderPage'
import { WorkLocationsPage } from './workLocations/WorkLocationsPage'
import { EnrollmentSuccessPage } from '../organization/onboarding/survey/enrollmenSuccess/EnrollmentSuccessPage'

const individualOrgRoutes = [
  { index: true, element: <OrgHomepage /> },
  { path: 'account', element: <Navigate to="../billing" replace /> },
  { path: 'accounts', element: <Navigate to="../billing" replace /> },
  { path: 'activities', element: <Navigate to="../pay-periods" replace /> },
  {
    path: 'alice-card-program',
    Component: EnableConnectComponents,
    handle: { title: 'Benefits' },
    children: [
      { index: true, element: <AliceCardProgramPage /> },
      {
        path: 'account',
        handle: { title: 'Account' },
        children: [
          {
            index: true,
            element: <FundingAccountPage />,
          },
          {
            path: 'setup',
            element: <FundingAccountSetupPage />,
          },
        ],
      },
    ],
  },
  { path: 'billing', element: <BillingPage />, handle: { title: 'Billing' } },
  {
    path: 'company-profile',
    handle: { title: 'Company Profile' },
    children: [
      { index: true, element: <CompanyProfilePage /> },
      {
        path: 'company-representative',
        element: <CompanyProfilePage />,
      },
    ],
  },
  { path: 'documents', element: <DocumentsPage />, handle: { title: 'Documents' } },
  {
    path: 'enrollment-success',
    element: <EnrollmentSuccessPage />,
    handle: { title: 'Enrollment success' },
  },
  { path: 'invoices', element: <Navigate to="../billing" replace /> },
  { path: 'locations', element: <WorkLocationsPage />, handle: { title: 'Locations' } },
  { path: 'order-form', element: <OrderFormPage />, handle: { title: 'Order form' } },
  { path: 'pay-periods', element: <PayPeriodsPage />, handle: { title: 'Pay periods' } },
  {
    path: 'payroll',
    element: <PayrollPage />,
    handle: { title: 'Payroll' },
  },
  {
    path: 'people',
    handle: { title: 'People' },
    children: [{ index: true, element: <EmployeeListPage /> }],
  },
  { path: 'profile', element: <AdminProfilePage />, handle: { title: 'Preferences' } },
  { path: 'payroll-reports', element: <PayrollReportsPage />, handle: { title: 'Payroll Reports' } },
  { path: 'reports', element: <ReportBuilderPage />, handle: { title: 'Reports' } },
  {
    path: ':employerId',
    handle: { title: 'Pay group' },
    children: [
      { index: true, element: <EmployerHomepage /> },
      { path: 'account', element: <Navigate to="../billing" replace /> },
      { path: 'accounts', element: <Navigate to="../billing" replace /> },
      { path: 'activities', element: <Navigate to="../pay-periods" replace /> },
      {
        path: 'billing',
        element: <BillingPage />,
        handle: { title: 'Billing' },
      },
      {
        path: 'documents',
        element: <DocumentsPage />,
        handle: { title: 'Documents' },
      },
      {
        path: 'finch-auth-gateway',
        index: true,
        element: <FinchAuthGateway />,
      },
      {
        path: 'pay-periods',
        element: <PayPeriodsPage />,
        handle: { title: 'Pay periods' },
      },
      {
        path: 'people',
        element: <EmployeeListPage />,
        handle: { title: 'People' },
      },
    ],
  },
  {
    path: 'beta',
    children: [],
  },
]

/**
 * These are the employee dashboard routes that should be mounted at the root of
 * the application.
 */
export const orgDashboardRoutes: RouteObject = {
  children: [
    {
      path: 'manage',
      children: [
        {
          path: 'org/:orgId',
          element: <OrgDashboardRouteChrome />,
          children: [...individualOrgRoutes, { path: '*', element: <NotFound /> }],
        },
        { index: true, element: <OrgRootRoute /> }, // the OrgRoot
        { path: '*', element: <OrgRootRoute /> }, // the OrgRoot
      ],
    },
  ],
}
