import { ActionLayout, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { Question } from '../../../survey/types'
import { useOrg } from '../../organization/useOrg'
import { KYCButton } from '../KYC/KYCButton'
import { RequestKYCHelpInfo } from '../KYC/RequestKYCHelpForm'

export const CompanyRepresentativeInfo = ({ org }: { org: OrganizationDetailFragment }) => {
  return (
    <>
      <Typography variant="body2" gutterBottom>
        Federal regulations require information on a Company Representative. The representative must be a US
        resident with a valid SSN.
      </Typography>
      <Typography variant="body2" component="ul" gutterBottom sx={{ listStyleType: 'disc', ml: 4 }}>
        <li>This is in connection with financial crimes enforcement act.</li>
        <li>This is not connected to your credit</li>
        <li>You are not making any financial commitments to this company</li>
        <li>Your SSN is used to verify that you are a real person</li>
        <li>
          If you have used TripleSeat in the past, then the person who signed up for TripleSeat at your
          company already has an account & can complete this step
        </li>
      </Typography>
      <RequestKYCHelpInfo org={org} />
      <ActionLayout primary={<KYCButton org={org} />} />
    </>
  )
}

export const CompanyRepresentativeInputs = () => {
  const org = useOrg()
  if (!org) return null
  return <CompanyRepresentativeInfo org={org} />
}

const QUESTIONS: Question[] = []
export const companyRepresentativeInputs = {
  Page: CompanyRepresentativeInputs,
  questions: QUESTIONS,
}
