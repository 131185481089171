import { HealthcheckList, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { PageBody } from '../../routes/PageBody'
import { useOrg } from '../organization/useOrg'
import { AliceCardProgramHealthcheck } from './AliceCardProgramHealthcheck'
import { useMayManageAliceCardProgram } from './aliceCardProgramUtils'

export const AliceCardProgramPage = () => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  const org = useOrg()
  if (!org) return null

  const todo = !org.onboardingState.isAlreadyLaunched
  if (!mayManageAliceCardProgram) return null

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Contributions Account</Typography>
      <HealthcheckList todo={todo} sx={{ my: 3 }}>
        <AliceCardProgramHealthcheck org={org} todo={todo} />
      </HealthcheckList>
    </PageBody>
  )
}
