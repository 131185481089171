/**
 * This is the more generic definition of 'chrome', which traditionally refers to all the "container" UI elements
 * before Google co-opted the term and ironically made it nearly un-googleable. This is the best summary
 * I've found: https://www.nngroup.com/articles/browser-and-gui-chrome/.
 */
import {
  Box,
  Container,
  Footer,
  FOOTER_MAX_HEIGHT,
  FooterProps,
  FooterText,
  Header,
  HeaderProps,
} from '@alice-financial/pretext-ui'
import { AppType } from '@alice-financial/pretext-ui/blocks/Header/Header'
import { datadogRum } from '@datadog/browser-rum'
import * as React from 'react'
import { ErrorBoundary, ErrorBoundaryPropsWithComponent } from 'react-error-boundary'
import { FormattedMessage } from 'react-intl'
import { Outlet } from 'react-router'
import { OffboardedOrgAlert } from '../organization/offboarding/OffboardedOrgAlert'
import { AvatarMenu } from '../user/AvatarMenu'
import { BreadcrumbProps, Breadcrumbs } from './Breadcrumb'
import { ErrorDisplay } from './ErrorDisplay'
import { NotFound } from './NotFound'

const trackError: ErrorBoundaryPropsWithComponent['onError'] = (error, info) => {
  datadogRum.addError(error, { ...info, source: 'ErrorBoundary' })
}

// Modifying the footer's content/height? Make sure to update `paddingBottom` at
// libraries/pretext-ui/theme/components.tsx (look up for "space for global
// absolutely-positioned footer").

const AppFooter = ({ homepagePath, sx }: { homepagePath: string } & Pick<FooterProps, 'sx'>) => (
  <Footer homepagePath={homepagePath} maxHeight={FOOTER_MAX_HEIGHT} overflow="auto" sx={sx}>
    <FooterText>
      <FormattedMessage
        id="footer.copyright"
        values={{ copyrightYear: `2020-${new Date().getFullYear()}` }}
      />
    </FooterText>
    <Container style={{ maxWidth: 585 }} sx={{ marginTop: 1 }}>
      <FooterText>
        <FormattedMessage id="footer.stripe_acknowledgment" />
      </FooterText>
      <FooterText>
        <FormattedMessage id="footer.visa_acknowledgment" />
      </FooterText>
    </Container>
  </Footer>
)

const APP_HOMEPAGE: Record<AppType, string> = {
  user: 'https://thisisalice.com',
  employee: '/',
  admin: '/manage',
}

type RouteChromeProps = {
  type?: HeaderProps['type']
  root?: BreadcrumbProps
  Nav?: React.ComponentType<{ drawerWidth: number }>
  pageTitle?: React.ReactNode
  notFound?: boolean
  children?: React.ReactNode
}
export const RouteChrome = ({
  children = <Outlet />,
  type = 'employee',
  root,
  Nav,
  pageTitle,
  notFound,
}: RouteChromeProps) => {
  const drawerWidth = Nav ? 200 : 0
  const width = `calc(100% - ${drawerWidth}px)`
  // Based on the behavior of the property `color` (see comment at `HeaderColor`
  // definition) this property is being used to derive the homepage link
  const homepagePath = root ? root.route : APP_HOMEPAGE[type]
  const breadcrumbs =
    type === 'admin' ? undefined : (
      <Box pl={1} pt={0.5}>
        <Breadcrumbs root={root} />
      </Box>
    )
  return (
    <>
      <Header
        type={type}
        homepagePath={homepagePath}
        pageTitle={pageTitle}
        titleOffset={drawerWidth}
        breadcrumbs={breadcrumbs}
      >
        <AvatarMenu />
      </Header>
      {notFound ? (
        <NotFound />
      ) : (
        <Box width={`calc(100% - ${drawerWidth}px)`} ml={`${drawerWidth}px`}>
          {type !== 'admin' && <OffboardedOrgAlert />}
          <ErrorBoundary fallback={<ErrorDisplay returnTo={root} />} onError={trackError}>
            {Nav && <Nav drawerWidth={drawerWidth} />}
            {children}
          </ErrorBoundary>
          <AppFooter homepagePath={homepagePath} sx={{ width }} />
        </Box>
      )}
    </>
  )
}
