import { HealthcheckList } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { LaunchDateHealthcheck } from '../../organization/onboarding/LaunchDateHealthcheck'
import { CompanyProfileHealthcheck } from '../../organization/onboarding/survey/companyProfile/CompanyProfilePage'
import { EnrollmentSuccessHealthcheck } from '../../organization/onboarding/survey/enrollmenSuccess/EnrollmentSuccessPage'
import { LocationsSurveyHealthcheck } from '../../organization/onboarding/survey/locations/LocationsSurvey'
import { PayrollHealthcheck } from '../../organization/onboarding/survey/payrollOps/PayrollHealthcheck'
import { AliceCardProgramHealthcheck } from '../aliceCardProgram/AliceCardProgramHealthcheck'
import { BillingHealthcheck } from '../billing/BillingHealthcheck'

type OrgHealthcheckProps = {
  org: OrganizationDetailFragment
}

export const OrgHealthcheck = ({ org }: OrgHealthcheckProps) => {
  if (!org) return null
  const todo = !org.onboardingState.isAlreadyLaunched
  return (
    <HealthcheckList todo={todo}>
      <PayrollHealthcheck org={org} todo={todo} />
      <CompanyProfileHealthcheck org={org} todo={todo} />
      <LocationsSurveyHealthcheck org={org} todo={todo} />
      <AliceCardProgramHealthcheck org={org} todo={todo} />
      <BillingHealthcheck org={org} todo={todo} />
      <EnrollmentSuccessHealthcheck org={org} todo={todo} />
      <LaunchDateHealthcheck org={org} todo={todo} />
    </HealthcheckList>
  )
}
