import { Box, BoxProps, Grid, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'

export const QuestionText = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body2" fontWeight="bold">
    {children}
  </Typography>
)
export const QuestionSubtext = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="caption" component="p" color="text.secondary">
    {children}
  </Typography>
)
export const QuestionContainer = (props: BoxProps) => <Box mb={4} {...props} />

export const QuestionPageDescription = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body2" gutterBottom>
    {children}
  </Typography>
)
export const QuestionPageHeading = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="h2" gutterBottom>
    {children}
  </Typography>
)

type UserInputsProps = {
  name: React.ReactNode
  email: React.ReactNode
  phone?: React.ReactNode
  company?: React.ReactNode
}
export const UserInputs = ({ name, email, phone, company }: UserInputsProps) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      {name}
    </Grid>
    {company && (
      <Grid item xs={12} md={6}>
        {company}
      </Grid>
    )}
    <Grid item xs={12} md={6}>
      {email}
    </Grid>
    {phone && (
      <Grid item xs={12} md={6}>
        {phone}
      </Grid>
    )}
  </Grid>
)
