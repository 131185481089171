import { Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Navigate } from 'react-router'
import { useLoadScript } from '../../../utils/useLoadScript'
import { PageBody } from '../../routes/PageBody'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { orgRootPath, useOrg } from '../organization/useOrg'
import { useOrgId } from '../useOrgId'
import { useOrderFormAcceptanceQuery } from './gql/orderFormAcceptance_gen'
import { useRequestOrderFormAcceptance } from './useRequestOrderFormAcceptance'

declare global {
  interface Window {
    jotformEmbedHandler: (iframeSelector: string, baseUrl: string) => void
  }
}

const EmbeddedForm = ({ formUrl }: { formUrl: string }) => {
  const onLoad = () => {
    // check for window.jotformEmbedHandler and call it if it exists, otherwise wait 1 second and try again
    window.jotformEmbedHandler("iframe[id='JotFormIFrame-250557090818057']", 'https://form.jotform.com/')
  }
  useLoadScript('embedded-form', 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js', { onLoad })
  return (
    <iframe
      id="JotFormIFrame-250557090818057"
      title="Alice Order Form"
      onLoad={() => window.parent.scrollTo(0, 0)}
      src={formUrl}
      frameBorder="0"
      style={{ minWidth: '100%', maxWidth: '100%', height: '539px', border: 'none' }}
      scrolling="no"
    />
  )
}

type OrderFormProps = {
  accepterId: number
}
const OrderForm = ({ accepterId }: OrderFormProps) => {
  const ownerId = useOrgId()
  const {
    mutate: requestOrderFormAcceptance,
    isLoading: isRequestingOrderFormAcceptance,
    isError,
  } = useRequestOrderFormAcceptance({ ownerId, accepterId })
  const { data: orderFormAcceptanceData, isFetching } = useOrderFormAcceptanceQuery({
    ownerId,
    accepterId,
  })
  const acceptance = orderFormAcceptanceData?.organization?.acceptance
  React.useEffect(() => {
    if (isFetching || acceptance || isRequestingOrderFormAcceptance || isError) return
    requestOrderFormAcceptance()
  }, [acceptance, isRequestingOrderFormAcceptance, requestOrderFormAcceptance, isFetching, isError])

  if (isRequestingOrderFormAcceptance) return <div>Requesting order form acceptance...</div>
  if (acceptance?.withdrawnAt) return <div>Order form withdrawn</div>
  if (acceptance?.acceptedAt) return <div>Order form accepted ({acceptance.signedDocument?.url})</div>
  if (!acceptance?.formUrl) return <div>Order form not found</div>

  return <EmbeddedForm formUrl={acceptance.formUrl} />
}

export const OrderFormPage = () => {
  const { data: currentUserData } = useCurrentUserQuery()
  const currentUser = currentUserData?.currentUser
  const org = useOrg()
  if (!org || !currentUser) return null
  if (org.contractSignedOn || org.onboardingState.isAlreadyLaunched) {
    return <Navigate to={`${orgRootPath(org.id)}`} />
  }
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Order form</Typography>
      <OrderForm accepterId={currentUser.id} />
    </PageBody>
  )
}
