import { useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { Button, ButtonProps, ExternalLink, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { getServerErrorHandler } from '../../../../../utils/forms/ServerError'
import { useOrg } from '../../../../orgDashboard/organization/useOrg'
import { Question } from '../../../../survey/types'
import { usePayrollInstructionsCompleteMutation } from '../../gql/payrollInstructionsComplete_gen'

type PayrollInstructionsCompleteProps = ButtonProps & {
  orgId: number
}
export const PayrollInstructionsCompleteButton = ({
  orgId,
  ...buttonProps
}: PayrollInstructionsCompleteProps) => {
  const { setError } = useForm()
  const onError = getServerErrorHandler(setError)
  const mutationOptions = useMutationOptionsWithInvalidation({ onError }, [useOrg.getKey({ orgId })])
  const {
    mutate: payrollInstructionsComplete,
    isLoading,
    isSuccess,
  } = usePayrollInstructionsCompleteMutation(mutationOptions)

  const handleClick = () => payrollInstructionsComplete({ input: { orgId } })

  return (
    <Button onClick={handleClick} variant="contained" disabled={isLoading || isSuccess} {...buttonProps}>
      <FormattedMessage id="organization.onboarding.payroll_instructions_complete" />
    </Button>
  )
}

const DEFAULT_PAYROLL_ARTICLE_LINK =
  'https://help.thisisalice.com/article/245-connecting-other-platform-and-alice'
const PAYROLL_ARTICLE_LINKS: Record<string, string | null> = {
  accupay: null,
  adp: null,
  adp_workforce: 'https://help.thisisalice.com/article/220-adp-wfn-setup-with-alice',
  adp_resource: null,
  run_adp: 'https://help.thisisalice.com/article/206-adp-run-setup-with-alice',
  'adp-totalsource': null,
  alliance: 'https://help.thisisalice.com/article/229-alliance-setup-with-alice',
  alliance_automated: 'https://help.thisisalice.com/article/229-alliance-setup-with-alice',
  aps: null,
  benefit_mall_pay_focus: null,
  'BenefitMall Pro': null,
  ceridian: null,
  cfs: null,
  compupay: null,
  payday_hcm: null,
  exact: null,
  gusto: 'https://help.thisisalice.com/article/209-gusto-setup-with-alice',
  heartland: null,
  heartland_payroll: null,
  intuit_online_payroll: null,
  justworks: null,
  kronos_workforce_ready: null,
  namely: null,
  on_pay: null,
  paychex: 'https://help.thisisalice.com/article/231-paychex-updated-integration',
  paychex_automated: 'https://help.thisisalice.com/article/231-paychex-updated-integration',
  paychex_flex: 'https://help.thisisalice.com/article/231-paychex-updated-integration',
  paychex_offline: null,
  paycom: 'https://help.thisisalice.com/article/230-paycom-setup-with-alice',
  paycor: 'https://help.thisisalice.com/article/252-connecting-paycor-and-alice',
  paycor_automated: 'https://help.thisisalice.com/article/252-connecting-paycor-and-alice',
  paylocity: 'https://help.thisisalice.com/article/211-paylocity-setup-with-alice',
  paylocity_automated: 'https://help.thisisalice.com/article/211-paylocity-setup-with-alice',
  paynorthwest: null,
  Payright: null,
  payrollontime: null,
  payroll_solutions: null,
  payville: null,
  Payville_USA: null,
  PlatinumPay: null,
  Premier_Payroll_Solutions: null,
  prg: null,
  primepay: null,
  prismhr: null,
  profit_builders: null,
  quickbooks_box: null,
  quickbooks_online: null,
  R365: null,
  rippling: 'https://help.thisisalice.com/article/225-rippling-setup-with-alice',
  rsi: null,
  sample: null,
  sdp: null,
  spur: null,
  sure_payroll: null,
  test: null,
  TimePays: null,
  stratex: 'https://help.thisisalice.com/article/202-toast-deductions-earnings-set-up',
  toast: 'https://help.thisisalice.com/article/202-toast-deductions-earnings-set-up',
  trax: null,
  ukg_ready: null,
  ultipro: null,
  ultipro_automated: null,
  valiant: null,
  Valiant_Trackforce: null,
  viventium: null,
  wells_fargo_direct_pay: null,
  xero: null,
  zenefits: null,
  other: null,
}

const PayrollConnectionInputs = () => {
  const org = useOrg()
  if (!org) return null
  const onboardingState = org.onboardingState
  const payrollPlatform = org.payrollConnection.payrollPlatform
  if (!payrollPlatform) {
    return (
      <Typography variant="body2" gutterBottom>
        <strong>Payroll platform not found</strong>
      </Typography>
    )
  }
  if (!onboardingState.isDoneWithPayrollInstructions) {
    return (
      <>
        <Typography variant="body2" gutterBottom>
          Your payroll platform is <strong>{payrollPlatform.name}</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
          <ExternalLink
            href={PAYROLL_ARTICLE_LINKS[payrollPlatform.code] || DEFAULT_PAYROLL_ARTICLE_LINK}
            target="_blank"
          >
            Learn how to set up payroll in Alice
          </ExternalLink>
        </Typography>
        <Typography variant="body2" gutterBottom>
          All set? Click{' '}
          <strong>
            <FormattedMessage id="organization.onboarding.payroll_instructions_complete" />
          </strong>{' '}
          below.
        </Typography>
        <PayrollInstructionsCompleteButton orgId={org.id} />
      </>
    )
  }
  if (!onboardingState.isPayrollConnected) {
    return (
      <>
        <Typography variant="body2" gutterBottom>
          We&apos;ll email you within 1 business day if we need anything more to confirm payroll setup.
        </Typography>
        <Typography variant="body2" gutterBottom>
          In the meantime, please click &quot;Next&quot; to complete or update your responses to the payroll
          survey.
        </Typography>
      </>
    )
  }
  return (
    <Typography variant="body2" color="success" fontWeight="bold" gutterBottom>
      Payroll connection confirmed
    </Typography>
  )
}

const QUESTIONS: Question[] = []
export const payrollConnection = {
  Page: PayrollConnectionInputs,
  questions: QUESTIONS,
}
