import { FormNameEnum } from '../../../graphql/generated.types'
import { useOrgId } from '../../../orgDashboard/useOrgId'
import { FormStatus } from '../../../survey/types'
import { useOrgFormResponseQuery } from './gql/orgFormResponse_gen'

export const useCurrentOrgFormFields = (formName: FormNameEnum, formVersion: number) => {
  const orgId = useOrgId()
  return useOrgFormFields(orgId, formName, formVersion)
}

export const useOrgFormFields = (orgId: number, formName: FormNameEnum, formVersion: number) => {
  const { data: formResponseData } = useOrgFormResponseQuery({
    id: orgId,
    formName,
    formVersion,
  })
  return formResponseData?.organization?.formResponse?.formFields
}

export const useOrgFormStatus = (orgId: number, formName: FormNameEnum, formVersion: number): FormStatus => {
  const { data: formResponseData } = useOrgFormResponseQuery({
    id: orgId,
    formName,
    formVersion,
  })
  if (!formResponseData) return 'notStarted'
  if (formResponseData?.organization?.formResponse?.complete) return 'complete'
  return 'inProgress'
}
