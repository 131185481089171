import * as React from 'react'
import { makeMultiSelectConfig } from '../../../../survey/questionTypes/MultiSelectQuestionDisplay'
import { FormInputProps } from '../../../../survey/types'

const WHAT_ARE_YOU_HOPING_ALICE_DOES = makeMultiSelectConfig({
  questionText: 'What are you hoping Alice does for you and your organization?',
  questionId: 'whatAreYouHopingAliceDoes',
  options: [
    'Save me time',
    'Increase employee retention',
    'More of our compensation reaches employees',
    'Engage more employees in our benefits',
    'Get us compliant with commuter benefit laws',
    'Help my employees save money',
  ],
  required: true,
})

const QUESTIONS = [WHAT_ARE_YOU_HOPING_ALICE_DOES] as const

const GoalsInputs = ({ control }: FormInputProps) => {
  return (
    <>
      {QUESTIONS.map(({ questionId, Input }) => (
        <Input key={questionId} control={control} />
      ))}
    </>
  )
}

export const goalsInputs = {
  Page: GoalsInputs,
  questions: QUESTIONS,
}
