import { CheckboxController, FormControl, FormGroup, FormHelperText } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Control, FieldValues, Path } from 'react-hook-form'
import { QuestionContainer, QuestionText } from '../surveyBlocks'
import { SelectMultipleQuestion } from '../types'

type MultSelectConfigProps = {
  questionText: string
  questionId: string
  options: SelectMultipleQuestion['select']['options']
  required: boolean
}
export const makeMultiSelectConfig = ({
  questionText,
  questionId,
  options,
  required,
}: MultSelectConfigProps) => {
  const question = {
    questionText,
    questionId,
    required,
    select: {
      options,
      multiple: true,
    },
    getFieldValue: (answer) => {
      const answers = answer?.split('|') || []
      return options.map((opt) => answers.includes(opt))
    },
    getAnswer: (values) => {
      const selections = values[questionId] || []
      return options.filter((_, index) => selections[index]).join('|')
    },
  } as const satisfies SelectMultipleQuestion
  return {
    ...question,
    Input: <T extends FieldValues>({ control }: { control: Control<T> }) => (
      <MultiSelectQuestionDisplay question={question} control={control} required={required} />
    ),
  }
}

type MultiSelectQuestionDisplayProps<T extends FieldValues> = {
  question: Pick<SelectMultipleQuestion, 'questionText' | 'questionId' | 'select'>
  control: Control<T>
  required?: boolean
}

const MultiSelectQuestionDisplay = <T extends FieldValues>({
  question,
  control,
  required,
}: MultiSelectQuestionDisplayProps<T>) => {
  const { questionText, questionId, select } = question
  return (
    <QuestionContainer>
      <QuestionText>{questionText}</QuestionText>
      <FormHelperText>Select all that apply {required && '(required)'}.</FormHelperText>
      <FormControl component="fieldset">
        <FormGroup>
          {select.options.map((label, index) => (
            <CheckboxController
              key={index}
              name={`${questionId}.${index}` as Path<T>} // using 'as' inference because array value is enforced by `question` prop type
              label={label}
              control={control}
              value={label}
              sx={{ marginY: -0.5, marginLeft: 1.5, marginRight: 0.5 }}
            />
          ))}
        </FormGroup>
      </FormControl>
    </QuestionContainer>
  )
}
