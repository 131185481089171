import {
  HealthcheckItem,
  HealthcheckItemProps,
  HealthcheckList,
  Typography,
  useActiveSlide,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { OrganizationDetailFragment } from '../../../../graphql/fragments/OrganizationFragment_gen'
import { AliceCardKycStatus, FormNameEnum } from '../../../../graphql/generated.types'
import { companyRepresentativeInputs } from '../../../../orgDashboard/aliceCardProgram/setup/CompanyRepresentativeInfo'
import { useCurrentOrgRootPath, useOrg } from '../../../../orgDashboard/organization/useOrg'
import { PageBody } from '../../../../routes/PageBody'
import { QuestionPage } from '../../../../survey/types'
import { PagedOrgForm } from '../PagedOrgForm'
import { useOrgFormStatus } from '../useOrgFormFields'
import { adminRoles } from './AdminRoleInputs'

/** * FORM CONFIGURATION ***/
const FORM_NAME = FormNameEnum.CompanyProfile
const FORM_VERSION = 1
const PAGES: readonly QuestionPage[] = [adminRoles, companyRepresentativeInputs]
/** * END FORM CONFIGURATION ***/

const useCompanyProfileFormStatus = (orgId: number) => useOrgFormStatus(orgId, FORM_NAME, FORM_VERSION)

/**
 * Hook to set the active slide to the most-actionable slide _if one is not already set_
 * 1. Don't do anything if neither survey nor KYC is complete
 * 2. If KYC is complete, go to first page of survey - admin roles
 * 3. If survey is complete, go to company representative info
 */
const useSetPreferredSlide = ({ org }: { org: OrganizationDetailFragment }) => {
  const formStatus = useCompanyProfileFormStatus(org.id)
  const isSurveyComplete = formStatus === 'complete'
  const [_, setActiveStep] = useActiveSlide()
  const [searchParams] = useSearchParams()
  React.useEffect(() => {
    if (isSurveyComplete === undefined || searchParams.has('step')) return
    const kycComplete = org.aliceCardProgram.kycStatus === AliceCardKycStatus.Complete
    if (kycComplete) {
      setActiveStep(PAGES.indexOf(adminRoles))
    } else if (isSurveyComplete) {
      setActiveStep(PAGES.indexOf(companyRepresentativeInputs), { replace: true })
    }
  }, [isSurveyComplete, searchParams, setActiveStep, org])
}

const SetPreferredSlide = ({ org }: { org: OrganizationDetailFragment }) => {
  useSetPreferredSlide({ org })
  return null
}

export const CompanyProfilePage = () => {
  const org = useOrg()
  if (!org) return null
  return (
    <PageBody maxWidth={800}>
      <SetPreferredSlide org={org} />
      <Typography variant="h1">Company profile</Typography>
      <HealthcheckList>
        <CompanyProfileHealthcheck org={org} />
      </HealthcheckList>
    </PageBody>
  )
}

const BaseHealthcheck = ({
  step,
  disableDone,
  ...props
}: Pick<HealthcheckItemProps, 'primary' | 'secondary' | 'status' | 'todo'> & {
  step?: number
  disableDone?: boolean
}) => {
  const currentOrgRootPath = useCurrentOrgRootPath()
  const baseTargetPath = `${currentOrgRootPath}/company-profile`
  const targetPath = step ? `${baseTargetPath}?step=${step}` : baseTargetPath
  return (
    <HealthcheckItem to={targetPath} newRequirement {...props}>
      <PagedOrgForm formName={FORM_NAME} formVersion={FORM_VERSION} pages={PAGES} disableDone={disableDone} />
    </HealthcheckItem>
  )
}

type CompanyProfileHealthcheckProps = {
  org: OrganizationDetailFragment
  todo?: boolean
}
export const CompanyProfileHealthcheck = ({ org, todo }: CompanyProfileHealthcheckProps) => {
  const formStatus = useCompanyProfileFormStatus(org.id)
  const kycComplete = org.aliceCardProgram.kycStatus === AliceCardKycStatus.Complete
  if (formStatus !== 'complete') {
    return (
      <BaseHealthcheck
        status="incomplete"
        primary="Complete your company profile survey"
        secondary="Tell us about your company and your goals for Alice"
        todo={todo}
        disableDone={!kycComplete}
      />
    )
  }
  if (!kycComplete) {
    return (
      <BaseHealthcheck
        todo={todo}
        status="incomplete"
        primary="Add Company Representative information"
        secondary="A legal US resident with a valid social security number for compliance purposes."
        step={PAGES.indexOf(companyRepresentativeInputs)}
        disableDone
      />
    )
  }
  return (
    <BaseHealthcheck
      todo={todo}
      primary="Company profile complete"
      secondary="We will use your responses to support you better"
      status="complete"
    />
  )
}
