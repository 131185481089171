import { Button } from '@alice-financial/pretext-ui'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { OrganizationDetailFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { AliceCardKycStatus } from '../../../graphql/generated.types'
import { useCreateAdminKycLinkMutation } from '../../homepage/gql/createAdminKYCLink_gen'

const KYCButtonLabel = ({ status }: { status?: AliceCardKycStatus }): JSX.Element => {
  switch (status) {
    case AliceCardKycStatus.Complete:
      return <FormattedMessage id="org.alice_card.kyc.complete_label" />
    case AliceCardKycStatus.Started:
      return <FormattedMessage id="org.alice_card.kyc.continue_label" />
    case AliceCardKycStatus.Pending:
      return <FormattedMessage id="org.alice_card.kyc.pending_verification_label" />
    default:
      return <FormattedMessage id="org.alice_card.kyc.start_label" />
  }
}

type KYCStepsProps = {
  org: OrganizationDetailFragment
}
export const KYCButton = ({ org }: KYCStepsProps) => {
  const {
    mutate: fetchKycLink,
    isLoading,
    isSuccess,
  } = useCreateAdminKycLinkMutation({
    onSuccess: (kycLinkData) => {
      if (!kycLinkData.createAdminKycLink?.adminKycLink) throw new Error('Invalid KYC link')
      window.location.assign(kycLinkData.createAdminKycLink?.adminKycLink)
    },
  })

  const kycStatus = org.aliceCardProgram?.kycStatus
  const isComplete = kycStatus === AliceCardKycStatus.Complete
  const isDisabled = isLoading || isSuccess || isComplete

  return (
    <Button
      variant="contained"
      onClick={() => fetchKycLink({ orgId: org.id })}
      disabled={isDisabled}
      endIcon={isComplete ? undefined : <ArrowForwardIosIcon />}
      sx={{ textWrap: 'nowrap', mt: -1 }}
    >
      <KYCButtonLabel status={kycStatus} />
    </Button>
  )
}
