import * as React from 'react'
import { makeSelectConfig } from '../../../../survey/questionTypes/SelectQuestionDisplay'
import { FormInputProps } from '../../../../survey/types'

const ProcessingDay = makeSelectConfig({
  questionText: 'What day of the week do you typically run payroll?',
  questionId: 'processingDay',
  options: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'No specific day'],
  required: true,
})
const ProcessingDayInput = ProcessingDay.Input

const QUESTIONS = [ProcessingDay]

const PayrollProcessInputs = ({ register }: FormInputProps) => {
  return <ProcessingDayInput register={register} />
}

export const payrollProcess = {
  Page: PayrollProcessInputs,
  questions: QUESTIONS,
}
