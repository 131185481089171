import * as React from 'react'
import { makeLongTextConfig } from '../../../../survey/questionTypes/LongTextQuestionDisplay'
import { makeTextConfig } from '../../../../survey/questionTypes/TextQuestionDisplay'
import { QuestionPageDescription, QuestionPageHeading } from '../../../../survey/surveyBlocks'
import { FormInputProps } from '../../../../survey/types'

const EVERY_EMPLOYEE_HAS_A_HOME_LOCATION = makeLongTextConfig({
  questionText: 'Home location',
  questionSubtext:
    'Do your employees have one home location where they always get paid regardless of where they worked?',
  questionId: 'everyEmployeeHasAHomeLocation',
  placeholder:
    'e.g., No, most employees are always in one location, but a few managers move between locations',
  required: true,
})
const EveryEmployeeHasAHomeLocation = EVERY_EMPLOYEE_HAS_A_HOME_LOCATION.Input
const HOME_LOCATION_TRACKING_SYSTEM = makeTextConfig({
  questionText: 'How do you track employees’ home locations?',
  questionId: 'homeLocationTrackingSystem',
  placeholder: 'e.g., In a spreadsheet',
  required: true,
})
const HomeLocationTrackingSystem = HOME_LOCATION_TRACKING_SYSTEM.Input

const HOW_OFTEN_BORROW = makeTextConfig({
  questionText: 'Temporary "borrows"',
  questionSubtext:
    'How often do you borrow an employee from another location? How do you handle their pay in those cases?',
  questionId: 'howOftenBorrow',
  placeholder: 'e.g., About once or twice a month.',
  required: true,
})
const HowOftenBorrow = HOW_OFTEN_BORROW.Input

const HOW_OFTEN_TRANSFER = makeTextConfig({
  questionText: 'Permanent "transfers"',
  questionSubtext:
    'How often do employees permanently move to a different home location, and how do you process that?',
  questionId: 'howOftenTransfer',
  placeholder: 'e.g, A few times a year.',
  required: true,
})
const HowOftenTransfer = HOW_OFTEN_TRANSFER.Input

const HOW_NOTIFIED_OF_BORROW_OR_TRANSFER = makeLongTextConfig({
  questionText: 'Notification process',
  questionSubtext: 'How do you learn about a borrow or transfer — forms, emails, system alerts?',
  questionId: 'howNotifiedOfBorrowOrTransfer',
  placeholder:
    'e.g., GMs submit a form to the payroll manager each Tuesday before payroll deadline. The form is here: (link to form).',
  required: true,
})
const HowNotifiedOfBorrowOrTransfer = HOW_NOTIFIED_OF_BORROW_OR_TRANSFER.Input

const QUESTIONS = [
  EVERY_EMPLOYEE_HAS_A_HOME_LOCATION,
  HOME_LOCATION_TRACKING_SYSTEM,
  HOW_OFTEN_BORROW,
  HOW_OFTEN_TRANSFER,
  HOW_NOTIFIED_OF_BORROW_OR_TRANSFER,
] as const
const MultiLocationInputs = ({ register }: FormInputProps) => {
  return (
    <>
      <QuestionPageHeading>Tracking employees between locations</QuestionPageHeading>
      <QuestionPageDescription>
        Alice helps multi-location employers automatically manage employees who move between locations,
        whether its rarely or often. Understanding how your team manages borrowing and transfers saves you
        time and ensures Alice benefits appear on the right paycheck, in the right pay group, at the right
        time.
      </QuestionPageDescription>

      <EveryEmployeeHasAHomeLocation register={register} />
      <HomeLocationTrackingSystem register={register} />
      <HowOftenBorrow register={register} />
      <HowOftenTransfer register={register} />
      <HowNotifiedOfBorrowOrTransfer register={register} />
    </>
  )
}

export const multiLocationInputs = {
  Page: MultiLocationInputs,
  questions: QUESTIONS,
}
