import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type RequestOrderFormAcceptanceMutationVariables = Types.Exact<{
  ownerId: Types.Scalars['Int']['input']
  accepterId: Types.Scalars['Int']['input']
}>

export type RequestOrderFormAcceptanceMutation = {
  __typename?: 'Mutation'
  requestAcceptance?: {
    __typename?: 'RequestAcceptancePayload'
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const RequestOrderFormAcceptanceDocument = `
    mutation RequestOrderFormAcceptance($ownerId: Int!, $accepterId: Int!) {
  requestAcceptance(
    input: {ownerId: $ownerId, accepterId: $accepterId, agreementType: pth_order_form}
  ) {
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useRequestOrderFormAcceptanceMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    RequestOrderFormAcceptanceMutation,
    TError,
    RequestOrderFormAcceptanceMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RequestOrderFormAcceptanceMutation,
    TError,
    RequestOrderFormAcceptanceMutationVariables,
    TContext
  >(
    ['RequestOrderFormAcceptance'],
    (variables?: RequestOrderFormAcceptanceMutationVariables) =>
      fetchGql<RequestOrderFormAcceptanceMutation, RequestOrderFormAcceptanceMutationVariables>(
        RequestOrderFormAcceptanceDocument,
        variables
      )(),
    options
  )
}
