import {
  Box,
  Button,
  Checkbox,
  CheckboxController,
  FormControl,
  FormGroup,
  SelectController,
  TextFieldController,
  Typography,
  useResponseNotification,
} from '@alice-financial/pretext-ui'
import React from 'react'
import { Control, useForm, UseFormSetValue } from 'react-hook-form'
import { Navigate, useNavigate, useParams } from 'react-router'
import { isNotNull } from '../../../../utils/typeUtils'
import {
  AnswerInputObject,
  FormSubmissionStatus,
  QuestionType,
  SubmissionRelation,
} from '../../../graphql/generated.types'
import { FormsSubmissionFragment } from '../../../user/gql/currentUser_gen'
import { useManageFormSubmission } from '../useManageFormSubmission'
import { QAPair } from './types'

type MultiSelectControllerProps = {
  selectedValues: Array<string>
  setValue: (newValues: Array<string>) => void
  options: Array<string>
}

type FormRelation = {
  id: number
  type: SubmissionRelation
}

export type FormSubmissionInput = {
  id?: number
  answers: AnswerInputObject[]
  subjectId?: number
  subjectType?: SubmissionRelation
  templateId: number
  status?: FormSubmissionStatus
}

const MultiSelectController = ({ selectedValues, setValue, options }: MultiSelectControllerProps) => {
  return (
    <FormControl component="fieldset" fullWidth>
      <FormGroup>
        {options?.map((option) => (
          <Checkbox
            key={option}
            name={option}
            checked={selectedValues.includes(option)}
            onChange={(e) => {
              if (e.target.checked) {
                setValue([...selectedValues, option])
              } else {
                setValue(selectedValues.filter((value) => value !== option))
              }
            }}
            label={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

const transformDataToFormValues = (data: QAPair[]) => {
  return data.reduce<Record<string, Array<string> | string | boolean>>(
    (acc, item) => ({
      ...acc,
      // the answer text arrives already parsed as record above - the backend has a custom
      // union that can accept any of the above values
      [`question-${item.question.id}`]: item.answer?.text || '',
    }),
    {}
  )
}

interface FormPageProps {
  data: QAPair[]
  hasPrevious: boolean
  hasNext: boolean
  currentPage: number
  templateId: number
  submission?: FormsSubmissionFragment
  subject?: FormRelation
}

const FormPage: React.FC<FormPageProps> = ({
  data,
  hasPrevious,
  hasNext,
  currentPage,
  submission,
  subject,
  templateId,
}) => {
  const navigate = useNavigate()
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: transformDataToFormValues(data),
  })
  const { mutate: manageFormSubmission } = useManageFormSubmission()
  const { notifySuccess } = useResponseNotification()

  const onSubmit = (
    formData: Record<string, string | boolean | string[]>,
    navUrl: string,
    status?: FormSubmissionStatus
  ) => {
    const answers = data
      .map((item) => {
        const key = `question-${item.question.id}`
        const value = formData[key]
        if (value === '' || value === undefined || value === 'undefined') return null

        return {
          id: item?.answer?.id,
          questionId: item.question.id,
          text: Array.isArray(value) ? value : value.toString(),
        }
      })
      .filter(isNotNull)

    const input: FormSubmissionInput = {
      id: submission?.id,
      answers,
      subjectId: subject?.id,
      subjectType: subject?.type,
      templateId,
      ...(status && { status }),
    }

    manageFormSubmission(input, {
      onSuccess: () => {
        navigate(navUrl)
        if (status === FormSubmissionStatus.Completed) {
          notifySuccess('Survey successfully submitted!')
        }
      },
    })
  }

  const handleNavigation = (direction: 'next' | 'previous' | 'final') => {
    if (direction === 'final') {
      handleSubmit((formData) => {
        const navUrl = `/`
        onSubmit(formData, navUrl, FormSubmissionStatus.Completed)
      })()
    } else {
      handleSubmit((formData) => {
        const navUrl = `/onboard/org-survey/${direction === 'next' ? currentPage + 1 : currentPage - 1}`
        onSubmit(formData, navUrl)
      })()
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleNavigation(hasNext ? 'next' : 'final')
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {data.map((item) => {
          const itemName = `question-${item.question.id}`
          return (
            <Box key={itemName} sx={{ p: 2 }}>
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                {item.question.text}
                <br />
                {item.question.subtitle && (
                  <Typography variant="caption" fontStyle="italic">
                    {item.question.subtitle}
                  </Typography>
                )}
              </Typography>
              <Question control={control} item={item} setValue={setValue} value={watch(itemName)} />
            </Box>
          )
        })}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
          {hasPrevious && (
            <Button variant="contained" onClick={() => handleNavigation('previous')}>
              Previous
            </Button>
          )}
          <Button variant="contained" type="submit">
            {hasNext ? 'Next' : 'Submit'}
          </Button>
        </Box>
      </Box>
    </form>
  )
}

const Question = ({
  item,
  control,
  setValue,
  value,
}: {
  item: QAPair
  control: Control
  setValue: UseFormSetValue<Record<string, string | boolean | string[]>>
  value: string | boolean | string[] | undefined
}) => {
  const itemName = `question-${item.question.id}`

  switch (item.question.questionType) {
    case QuestionType.Text:
      return (
        <TextFieldController
          name={itemName}
          control={control}
          multiline
          fullWidth
          placeholder={item.question?.placeholder || undefined}
          rows={2}
          InputProps={{ sx: { padding: 0 } }}
        />
      )
    case QuestionType.Select:
      return (
        <SelectController name={itemName} control={control}>
          {item.question.options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </SelectController>
      )
    case QuestionType.Boolean:
      return <CheckboxController name={itemName} control={control} label={item.question.text} />
    case QuestionType.Multiple: {
      const arrayValue = Array.isArray(value) ? value : []
      return (
        <MultiSelectController
          setValue={(newValues: Array<string>) => setValue(itemName, newValues)}
          selectedValues={arrayValue}
          options={item.question.options || []}
        />
      )
    }
    default:
      return null
  }
}

interface FormManagerProps {
  QAList: QAPair[]
  pageSize: number
  templateId: number
  submission?: FormsSubmissionFragment
  subject?: FormRelation
}

// FormManager component to handle state and navigation - leaves all the form work to the pages
export const FormManager: React.FC<FormManagerProps> = ({
  QAList,
  pageSize,
  submission,
  subject,
  templateId,
}) => {
  const { page } = useParams()
  const currentPage = page ? parseInt(page, 10) : 1
  if (!page) return <Navigate to="/onboard/org-survey/1" replace />

  const startIndex = (currentPage - 1) * pageSize
  const currentQuestions = QAList.slice(startIndex, startIndex + pageSize)

  // starting from current page and including all rendered items, are there any items remaining?
  const hasNextPage = startIndex + pageSize < QAList.length
  const hasPrevPage = currentPage > 1

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <FormPage
        data={currentQuestions}
        hasNext={hasNextPage}
        hasPrevious={hasPrevPage}
        currentPage={currentPage}
        submission={submission}
        subject={subject}
        templateId={templateId}
      />
    </Box>
  )
}
