import { QueryKey, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
import { ApiRootException } from '../responseErrors'
import { GqlInputErrorException } from './responseErrors'

export type GqlApiError<TVariables extends Record<string, unknown> | void> =
  | ApiRootException
  | GqlInputErrorException<TVariables>

export type UseGqlMutationCallbacks<
  TData = unknown,
  TVariables extends Record<string, unknown> | void = void,
  TContext = unknown,
> = Pick<
  UseMutationOptions<TData, GqlApiError<TVariables>, TVariables, TContext>,
  'onMutate' | 'onError' | 'onSuccess' | 'onSettled'
>

export type UseGqlQueryCallbacks<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TVariables extends Record<string, unknown> = Record<string, never>,
> = Pick<
  UseQueryOptions<TQueryFnData, GqlApiError<TVariables>, TData, TQueryKey>,
  'onError' | 'onSuccess' | 'onSettled'
>
