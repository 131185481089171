import { FormHelperText, Select } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { QuestionContainer, QuestionText } from '../surveyBlocks'
import { SelectQuestion } from '../types'

type SelectConfigProps<TOptions extends SelectQuestion['select']['options']> = {
  questionText: string
  questionId: string
  options: TOptions
  required: boolean
  defaultValue?: TOptions[number]
}
export const makeSelectConfig = <TOptions extends SelectQuestion['select']['options']>({
  questionText,
  questionId,
  options,
  required,
  defaultValue,
}: SelectConfigProps<TOptions>) => {
  const question = {
    questionText,
    questionId,
    required,
    select: { options },
    getFieldValue: (answer) => answer || defaultValue || '',
    getAnswer: (values) => values[questionId] || defaultValue || '',
  } as const satisfies SelectQuestion
  return {
    ...question,
    Input: <T extends FieldValues>({ register }: { register: UseFormRegister<T> }) => (
      <SelectQuestionDisplay
        question={question}
        register={register}
        required={required}
        defaultValue={defaultValue}
      />
    ),
  }
}

type SelectQuestionDisplayProps<T extends FieldValues> = {
  question: Pick<SelectQuestion, 'questionText' | 'questionId' | 'select'>
  register: UseFormRegister<T>
  required?: boolean
  defaultValue?: string
}

const SelectQuestionDisplay = <T extends FieldValues>({
  question,
  register,
  required,
  defaultValue,
}: SelectQuestionDisplayProps<T>) => {
  const { questionText, questionId, select } = question
  return (
    <QuestionContainer>
      <QuestionText>{questionText}</QuestionText>

      <Select variant="outlined" {...register(questionId as Path<T>)}>
        {select.options.map((label, index) => (
          <option key={index} value={label} selected={defaultValue === label}>
            {label}
          </option>
        ))}
      </Select>
      {required && <FormHelperText>required</FormHelperText>}
    </QuestionContainer>
  )
}
