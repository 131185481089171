import * as React from 'react'
import { FormNameEnum } from '../../../graphql/generated.types'
import { PagedForm } from '../../../survey/PagedForm'
import { QuestionPage } from '../../../survey/types'
import { useCreateOrgFormResponse } from './useCreateOrgFormResponse'
import { useCurrentOrgFormFields, useOrgFormStatus } from './useOrgFormFields'
import { useOrgId } from '../../../orgDashboard/useOrgId'

type PagedOrgFormProps = {
  formName: FormNameEnum
  formVersion: number
  pages: readonly QuestionPage[]
  onCompleteReturnTo?: string
  disableDone?: boolean
}
export const PagedOrgForm = ({
  formName,
  formVersion,
  pages,
  onCompleteReturnTo,
  disableDone,
}: PagedOrgFormProps) => {
  const orgId = useOrgId()
  const surveyStatus = useOrgFormStatus(orgId, formName, formVersion)
  const formFields = useCurrentOrgFormFields(formName, formVersion)
  const { mutateAsync: createFormResponse } = useCreateOrgFormResponse({ formName, formVersion })

  return (
    <PagedForm
      formName={formName}
      formVersion={formVersion}
      pages={pages}
      onCompleteReturnTo={onCompleteReturnTo}
      formFields={formFields}
      createFormResponse={createFormResponse}
      disableDone={disableDone}
      status={surveyStatus}
    />
  )
}
