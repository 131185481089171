import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Box,
  Button,
  ButtonProps,
  Container,
  MobileStepper,
  Theme,
  useMediaQuery,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavigateOptions, useSearchParams } from 'react-router-dom'
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views'

export const useActiveSlide = (disableNav?: boolean) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchStep = searchParams.get('step') || '0'
  const searchStepInt = parseInt(searchStep, 10)
  const [internalStep, setInternalStep] = React.useState(0)
  if (disableNav) {
    return [internalStep, setInternalStep] as const
  }
  const setSearchStep = (setter: number | ((prev: number) => number), navigateOpts?: NavigateOptions) => {
    const newStep = typeof setter === 'number' ? setter : setter(searchStepInt)
    searchParams.set('step', newStep.toString())
    setSearchParams(searchParams, navigateOpts)
  }

  return [parseInt(searchStep, 10), setSearchStep] as const
}
const NavButton = ({ size = 'small', ...props }: ButtonProps) => {
  return <Button size={size} variant="contained" {...props} />
}

type CarouselProps = Pick<SwipeableViewsProps, 'action' | 'animateHeight' | 'children'> & {
  onComplete?: () => void
  disableNav?: boolean
  onStepChange?: (step: number) => void
  nextButtonLabel?: React.ReactNode
  backButtonLabel?: React.ReactNode
  mouseOnly?: boolean
  disableDone?: boolean
}
/**
 * Compone that renders a carousel using MUI's Stepper component to cycle through
 * images and text describing how the product works. Each section will have a title,
 * description, and image.
 */
export const Carousel = ({
  children,
  action,
  animateHeight,
  disableNav,
  onComplete,
  onStepChange,
  nextButtonLabel,
  backButtonLabel = <FormattedMessage id="common.back" />,
  mouseOnly,
  disableDone,
}: CarouselProps) => {
  const [activeStep, setActiveStep] = useActiveSlide(disableNav)
  const isMobileWidth = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const numSteps = React.Children.count(children)
  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === numSteps - 1
  const handleStepChange = (step: number) => {
    setActiveStep(step)
    onStepChange?.(step)
  }
  const defaultNextButtonLabel = isLastStep ? (
    <FormattedMessage id="common.done" />
  ) : (
    <FormattedMessage id="common.next" />
  )
  return (
    <Box display="flex" p={0} mb={2}>
      <Box width="100%" maxWidth="90vw" flexGrow={1}>
        <SwipeableViews
          disabled={mouseOnly}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          action={action}
          animateHeight={animateHeight}
        >
          {children}
        </SwipeableViews>
        <Container
          component={MobileStepper}
          steps={numSteps}
          position={isMobileWidth ? 'bottom' : 'static'}
          activeStep={activeStep}
          nextButton={
            <NavButton
              variant="contained"
              onClick={isLastStep ? onComplete : () => handleStepChange(activeStep + 1)}
              sx={{ opacity: isLastStep && disableDone ? 0 : 1 }}
            >
              {nextButtonLabel || defaultNextButtonLabel}
              {!isLastStep && <ChevronRightIcon />}
            </NavButton>
          }
          backButton={
            <NavButton
              variant="outlined"
              onClick={() => handleStepChange(activeStep - 1)}
              disabled={isFirstStep}
              aria-hidden={isFirstStep}
              sx={{ opacity: isFirstStep ? 0 : 1 }}
            >
              <ChevronLeftIcon />
              {backButtonLabel}
            </NavButton>
          }
          sx={{ padding: '0 !important', display: 'flex' }}
        />
      </Box>
    </Box>
  )
}
