import * as React from 'react'
import { makeSelectConfig } from '../../../../survey/questionTypes/SelectQuestionDisplay'
import { makeTextConfig } from '../../../../survey/questionTypes/TextQuestionDisplay'
import { QuestionPageDescription, QuestionPageHeading } from '../../../../survey/surveyBlocks'
import { FormInputProps } from '../../../../survey/types'

export const HAS_MULTIPLE_LOCATIONS_FIELD = 'multipleLocations'
const LOCATIONS = makeSelectConfig({
  questionText: 'Do you have multiple locations?',
  questionId: HAS_MULTIPLE_LOCATIONS_FIELD,
  required: true,
  options: ['Yes', 'No'] as const,
  defaultValue: 'Yes',
})
const LocationsInput = LOCATIONS.Input
const SHIFT_SCHEDULING_SYSTEM = makeTextConfig({
  questionText: 'What system do you use for shift scheduling?',
  questionId: 'shiftSchedulingSystem',
  placeholder: 'We use 7Shifts',
  required: true,
})
const ShiftSchedulingSystem = SHIFT_SCHEDULING_SYSTEM.Input
const PAY_GROUPS_COUNT = makeTextConfig({
  questionText: 'How many pay groups do you have?',
  questionId: 'payGroups',
  placeholder: '9 - one for each location plus the salaried pay group and a catering/events pay group.',
  required: true,
})
const PayGroupCount = PAY_GROUPS_COUNT.Input

const BASIC_LOCATION_QUESTIONS = [LOCATIONS, SHIFT_SCHEDULING_SYSTEM, PAY_GROUPS_COUNT] as const

const BasicLocationQuestions = ({ register }: FormInputProps) => {
  return (
    <>
      <QuestionPageHeading>Locations &amp; Pay Groups</QuestionPageHeading>
      <QuestionPageDescription>
        Alice automatically tracks employees who move between locations or pay groups.
      </QuestionPageDescription>
      <QuestionPageDescription>
        Let&apos;s get everything set so benefits appear on the right paycheck.
      </QuestionPageDescription>

      <LocationsInput register={register} />
      <ShiftSchedulingSystem register={register} />
      <PayGroupCount register={register} />
    </>
  )
}

export const basicLocationInputs = {
  Page: BasicLocationQuestions,
  questions: BASIC_LOCATION_QUESTIONS,
}
