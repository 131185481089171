import { GqlApiError } from '@alice-financial/api'
import {
  ActionLayout,
  Box,
  Button,
  Carousel,
  LinearProgress,
  useActiveSlide,
} from '@alice-financial/pretext-ui'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useMutationNotifier } from '../../utils/useMutationNotifier'
import { FormField, FormNameEnum } from '../graphql/generated.types'
import { useCurrentOrgRootPath } from '../orgDashboard/organization/useOrg'
import {
  CreateFormResponseVariables,
  UseCreateFormResponseMutationOptions,
} from '../organization/onboarding/survey/useCreateFormResponse'
import { CreateFormResponseMutation } from '../user/gql/createFormResponse_gen'
import { fieldAnswer } from './questionTypes/formFieldUtils'
import { FormStatus, Question, QuestionPage, SurveyFieldValues } from './types'
import { FormattedMessage } from 'react-intl'

const useValuesFromApi = (
  formFields: FormField[] | undefined,
  pages: readonly QuestionPage[]
): SurveyFieldValues => {
  return React.useMemo(
    () =>
      pages
        .flatMap(({ questions }) => questions)
        .reduce((acc, question) => {
          const answer = fieldAnswer(formFields, question.questionId)
          acc[question.questionId] = question.getFieldValue(answer)
          return acc
        }, {} as SurveyFieldValues),
    [formFields, pages]
  )
}

/**
 * Read form input values and return the form fields to be saved to the database,
 * along with a dedicated 'complete' field to indicate if the form has been completed.
 */
const parseFormInputs = (allQuestions: Question[], values: SurveyFieldValues) => {
  return allQuestions.reduce<{ complete: boolean; formFields: FormField[] }>(
    (acc, { questionId, questionText: question, getAnswer, required }) => {
      // @ts-expect-error - values guaranteed to have correct key-value
      const answer = getAnswer(values)
      if (required && !answer) acc.complete = false
      acc.formFields.push({ question, answer, questionId })
      return acc
    },
    {
      complete: true,
      formFields: [],
    }
  )
}

type PagedFormProps = {
  formName: FormNameEnum
  formVersion: number
  formFields: FormField[] | undefined
  pages: readonly QuestionPage[]
  onCompleteReturnTo?: string // optional path to redirect to after form is submitted, relative to current org root path
  createFormResponse: UseMutateAsyncFunction<
    CreateFormResponseMutation,
    GqlApiError<CreateFormResponseVariables>,
    CreateFormResponseVariables
  >
  disableDone?: boolean
  status?: FormStatus
}
export const PagedForm = ({
  formName,
  formVersion,
  formFields,
  pages,
  onCompleteReturnTo,
  createFormResponse,
  disableDone,
  status,
}: PagedFormProps) => {
  const isStarted = status !== 'notStarted'
  const currentOrgRootPath = useCurrentOrgRootPath()
  const allQuestions: Question[] = React.useMemo(() => pages.flatMap((page) => page.questions), [pages])
  // use a memoized key to track the question ids to force remount when questions change (allows carousel layout to be updated)
  const questionsDefKey = React.useMemo(
    () => allQuestions.map(({ questionId }) => questionId).join(),
    [allQuestions]
  )

  const [activeStep] = useActiveSlide()
  const navigate = useNavigate()
  const values = useValuesFromApi(formFields, pages)
  const {
    handleSubmit,
    register,
    control,
    formState: { isDirty },
  } = useForm<SurveyFieldValues>({ values })
  const getSubmit = (mutationOptions?: UseCreateFormResponseMutationOptions) =>
    handleSubmit((values) => {
      createFormResponse(parseFormInputs(allQuestions, values), mutationOptions)
    })

  const onCompleteMutationOptions: UseCreateFormResponseMutationOptions = useMutationNotifier(
    {},
    {
      onSuccess: (_, { complete }) => {
        const returnTo =
          onCompleteReturnTo && complete ? `${currentOrgRootPath}/${onCompleteReturnTo}` : currentOrgRootPath
        navigate(returnTo)
      },
    }
  )
  const onSubmit = getSubmit()
  const onComplete = getSubmit(onCompleteMutationOptions)

  // single page version - no carousel
  if (pages.length === 1 && pages[0]) {
    const Page = pages[0].Page
    return (
      <>
        <Page register={register} control={control} formName={formName} formVersion={formVersion} />
        <ActionLayout
          primary={
            <Button variant="contained" fullWidth onClick={onComplete}>
              Done
            </Button>
          }
        />
      </>
    )
  }

  const previousPage = pages[activeStep - 1]
  const hasPreviousPageWithQuestions = previousPage && previousPage.questions.length > 0
  const nextPage = pages[activeStep + 1]
  const hasNextPageWithQuestions = nextPage && nextPage.questions.length > 0
  const activePage = pages[activeStep]
  const activePageHasQuestions = activePage && activePage.questions.length > 0
  const backButtonLabel = activePageHasQuestions ? (
    <FormattedMessage id="common.back" />
  ) : hasPreviousPageWithQuestions ? (
    isStarted ? (
      <FormattedMessage id="paged_form.edit_survey" />
    ) : (
      <FormattedMessage id="paged_form.start_survey" />
    )
  ) : undefined
  const nextButtonLabel = activePageHasQuestions ? (
    <FormattedMessage id="common.next" />
  ) : hasNextPageWithQuestions ? (
    isStarted ? (
      <FormattedMessage id="paged_form.edit_survey" />
    ) : (
      <FormattedMessage id="paged_form.start_survey" />
    )
  ) : undefined
  return (
    <>
      <Box mb={2} width="100%" marginX="auto" bgcolor="#ddd">
        <LinearProgress variant="determinate" value={((activeStep + 1) / pages.length) * 100} />
      </Box>
      <form onSubmit={onSubmit}>
        <Carousel
          key={questionsDefKey} // force remount when questions change
          action={(actions) => actions.updateHeight()}
          animateHeight
          onStepChange={() => isDirty && onSubmit()}
          onComplete={onComplete}
          mouseOnly
          disableDone={disableDone}
          backButtonLabel={backButtonLabel}
          nextButtonLabel={nextButtonLabel}
        >
          {pages.map((page, index) => (
            <Box key={index} pb={2}>
              <page.Page
                register={register}
                control={control}
                formName={formName}
                formVersion={formVersion}
              />
            </Box>
          ))}
        </Carousel>
      </form>
    </>
  )
}
