import * as React from 'react'
import { makeLongTextConfig } from '../../../../survey/questionTypes/LongTextQuestionDisplay'
import { QuestionPageDescription, QuestionPageHeading } from '../../../../survey/surveyBlocks'
import { FormInputProps } from '../../../../survey/types'

const LANGUAGES = makeLongTextConfig({
  questionText: 'What languages are spoken by your employees?',
  questionId: 'languages',
  placeholder: 'e.g., Mostly English and some Spanish',
  required: true,
})
const Languages = LANGUAGES.Input
const LANGUAGE_PREFERENCE_SOURCE = makeLongTextConfig({
  questionText: 'How do you keep track of the languages your employees prefer to use?',
  questionId: 'languagePreferenceSource',
  placeholder: 'e.g. We track it in a spreadsheet',
  required: true,
})
const LanguagePreferenceSource = LANGUAGE_PREFERENCE_SOURCE.Input

const QUESTIONS = [LANGUAGE_PREFERENCE_SOURCE] as const

const LanguagesInputs = ({ register }: FormInputProps) => {
  return (
    <>
      <QuestionPageHeading>Languages</QuestionPageHeading>
      <QuestionPageDescription>
        Alice provides enrollment materials and support to your employees in the language they prefer. Tell us
        about those languages and how we might set their preferred language in our system.
      </QuestionPageDescription>

      <Languages register={register} />
      <LanguagePreferenceSource register={register} />
    </>
  )
}

export const languages = {
  Page: LanguagesInputs,
  questions: QUESTIONS,
}
