import { List, ListItem, ListItemText, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { isNotNull } from '../../../utils/typeUtils'
import {
  OrganizationDetailFragment,
  PaymentMethodConnectionFragment,
  PaymentMethodFragment,
} from '../../graphql/fragments/OrganizationFragment_gen'
import { PaygroupDisplayInfo, WithPaygroupInfo } from '../types'

const findActivePaymentMethodConnections = (
  org: OrganizationDetailFragment
): Array<PaymentMethodConnectionFragment & WithPaygroupInfo> => {
  if (org.aliceCardProgram.hasUnifiedBanking) return org.aliceCardFunding.paymentMethodConnections || []
  return org.employers.filter(isNotNull).flatMap((employer) =>
    (employer.aliceCardFunding.paymentMethodConnections || [])
      .filter((c) => c.isActive)
      .map((cnxn) => ({
        ...cnxn,
        paygroupInfo: { id: employer.id, name: employer.name || '' },
      }))
  )
}

type PaymentMethodWithPaygroups = PaymentMethodFragment & {
  paygroups: Array<PaygroupDisplayInfo>
}
const findGroupedPaymentMethods = (org: OrganizationDetailFragment): Array<PaymentMethodWithPaygroups> => {
  const cnxns = findActivePaymentMethodConnections(org)
  return cnxns.reduce<Array<PaymentMethodWithPaygroups>>((acc, cnxn) => {
    const existing = acc.find((pm) => pm.id === cnxn.paymentMethod.id)
    if (existing && cnxn.paygroupInfo) {
      existing.paygroups.push(cnxn.paygroupInfo)
    } else {
      acc.push({
        ...cnxn.paymentMethod,
        paygroups: cnxn.paygroupInfo ? [cnxn.paygroupInfo] : [],
      })
    }
    return acc
  }, [])
}

const PaymentMethodPaygroupList = ({ paygroups }: { paygroups: Array<PaygroupDisplayInfo> }) => {
  if (paygroups.length === 0) return null
  return (
    <List dense>
      {paygroups.map((pg) => (
        <ListItem key={pg.id} disableGutters disablePadding sx={{ justifyContent: 'flex-end' }}>
          <Typography variant="caption" textAlign="right" sx={{ textWrap: 'nowrap' }}>
            {pg.name}
          </Typography>
        </ListItem>
      ))}
    </List>
  )
}

type ConnectedPaymentMethodsProps = {
  org: OrganizationDetailFragment
}
export const ConnectedPaymentMethods = ({ org }: ConnectedPaymentMethodsProps) => {
  const groupedPaymentMethods = findGroupedPaymentMethods(org)
  if (groupedPaymentMethods.length === 0) return null

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Funding method{groupedPaymentMethods.length > 1 && 's'}
      </Typography>

      <List>
        {groupedPaymentMethods.map((paymentMethod) => (
          <ListItem key={paymentMethod.id} divider>
            <ListItemText
              primary={
                <Typography color="primary" fontWeight="bold">
                  {paymentMethod.descriptor}
                </Typography>
              }
              secondary={
                <Typography variant="caption">
                  {`account ending in (${paymentMethod.last4})`}
                  {paymentMethod.paygroups.length > 0 &&
                    ` • ${paymentMethod.paygroups.length} pay group${paymentMethod.paygroups.length > 1 ? 's' : ''}`}
                </Typography>
              }
            />
            <PaymentMethodPaygroupList paygroups={paymentMethod.paygroups} />
          </ListItem>
        ))}
      </List>
    </>
  )
}
