import { HealthcheckItem, HealthcheckItemProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { isNotNull } from '../../../../../utils/typeUtils'
import { OrganizationDetailFragment } from '../../../../graphql/fragments/OrganizationFragment_gen'
import { useCurrentOrgRootPath } from '../../../../orgDashboard/organization/useOrg'
import { workLocationInputs } from '../../../../orgDashboard/workLocations/WorkLocationList'
import { QuestionPage } from '../../../../survey/types'
import { PagedOrgForm } from '../PagedOrgForm'
import { basicLocationInputs, HAS_MULTIPLE_LOCATIONS_FIELD } from './BasicLocationsInputs'
import { FORM_NAME, FORM_VERSION, useLocationsFormStatus, useLocationsSurveyFieldAnswers } from './config'
import { multiLocationInputs } from './MultiLocationInputs'

const ALL_PAGES: readonly QuestionPage[] = [basicLocationInputs, multiLocationInputs, workLocationInputs]
export const useLocationsSurveyPages = () => {
  const locationCountAnswer = useLocationsSurveyFieldAnswers(HAS_MULTIPLE_LOCATIONS_FIELD)
  if (locationCountAnswer === 'No') return ALL_PAGES.filter((page) => page !== multiLocationInputs)
  return ALL_PAGES
}

const BaseHealthcheck = ({
  step,
  ...props
}: Pick<HealthcheckItemProps, 'primary' | 'secondary' | 'status' | 'todo'> & {
  step?: number
}) => {
  const currentOrgRootPath = useCurrentOrgRootPath()
  const baseTargetPath = `${currentOrgRootPath}/locations`
  const targetPath = step ? `${baseTargetPath}?step=${step}` : baseTargetPath

  const pages = useLocationsSurveyPages()
  return (
    <HealthcheckItem to={targetPath} newRequirement {...props}>
      <PagedOrgForm formName={FORM_NAME} formVersion={FORM_VERSION} pages={pages} />
    </HealthcheckItem>
  )
}
type LocationsSurveyHealthcheckProps = {
  org: OrganizationDetailFragment
  todo?: boolean
}
export const LocationsSurveyHealthcheck = ({ org, todo }: LocationsSurveyHealthcheckProps) => {
  const pages = useLocationsSurveyPages()
  const formStatus = useLocationsFormStatus(org.id)
  const locations = org.workLocations.filter(isNotNull)
  const hasLocations = locations.length > 0

  if (formStatus !== 'complete') {
    return (
      <BaseHealthcheck
        primary="Complete your locations & pay groups survey"
        secondary="Tell us about your locations, pay groups, and home locations"
        status="incomplete"
        todo={todo}
      />
    )
  }
  if (!hasLocations) {
    return (
      <BaseHealthcheck
        primary="Work locations"
        secondary="Where do your employees work?"
        status={hasLocations ? 'complete' : 'incomplete'}
        step={pages.indexOf(workLocationInputs)}
        todo={todo}
      />
    )
  }
  const oneLocation = locations.length === 1
  return (
    <BaseHealthcheck
      primary={`Locations & pay groups set up (${locations.length} location${oneLocation ? '' : 's'})`}
      secondary="We will use your responses to support you better"
      status="complete"
      todo={todo}
      step={pages.indexOf(workLocationInputs)}
    />
  )
}
