import { FormNameEnum } from '../../../../graphql/generated.types'
import { fieldAnswer } from '../../../../survey/questionTypes/formFieldUtils'
import { useCurrentOrgFormFields, useOrgFormStatus } from '../useOrgFormFields'

/** * FORM CONFIGURATION ***/
export const FORM_NAME = FormNameEnum.LocationsInfo
export const FORM_VERSION = 1

export const useLocationsFormStatus = (orgId: number) => useOrgFormStatus(orgId, FORM_NAME, FORM_VERSION)
export const useLocationsSurveyFieldAnswers = (fieldName: string) =>
  fieldAnswer(useCurrentOrgFormFields(FORM_NAME, FORM_VERSION), fieldName)
