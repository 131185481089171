import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { useOrderFormAcceptanceQuery } from './gql/orderFormAcceptance_gen'
import {
  RequestOrderFormAcceptanceMutation,
  useRequestOrderFormAcceptanceMutation,
} from './gql/requestOrderFormAcceptance_gen'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'

type RequestOrderFormAcceptanceMutationOptions = UseGqlMutationCallbacks<RequestOrderFormAcceptanceMutation>
export const useRequestOrderFormAcceptance = (
  { ownerId, accepterId }: { ownerId: number; accepterId: number },
  mutationOptions?: RequestOrderFormAcceptanceMutationOptions
) => {
  const { mutateAsync: requestOrderFormAcceptance } = useRequestOrderFormAcceptanceMutation()
  const optionsWithNotifier = useMutationNotifier({ onSuccess: false }, mutationOptions)
  return useMutationWithInvalidation(
    () => requestOrderFormAcceptance({ ownerId, accepterId }),
    optionsWithNotifier,
    [useOrderFormAcceptanceQuery.getKey({ ownerId, accepterId })]
  )
}
