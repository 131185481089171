import { Container, ExternalLink, Grid, InfoDrawer, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'

const InfoContent = () => (
  <Container>
    <Typography variant="body2" gutterBottom>
      Alice connects to the account you use for payroll each pay period (Payroll Bank Account). Each pay
      period, when your employees make pretax contributions from their paychecks, Alice transfers those
      employee contributions from your Payroll Bank Account to your Benefits Contributions Account. Employees
      may use their pretax contributions to make eligible parking and transit purchases using their Alice
      Card.
    </Typography>
    <Typography variant="body2" fontStyle="italic" gutterBottom>
      <strong>Note</strong>: The source of these funds are employees&apos; pretax contributions. These are not
      Alice&apos;s fees. These are not costs for your organization.
    </Typography>
    <Typography variant="body2" gutterBottom>
      To verify your the Payroll Bank Account for each pay group, you can provide the account and routing
      number or you can log in to verify through Stripe, our financial services parter.
    </Typography>
    <Typography variant="body2" fontStyle="italic" gutterBottom>
      <strong>Note</strong>: Alice does not store account credentials
    </Typography>
    <Typography variant="body2" gutterBottom>
      Please contact <ExternalLink href="mailto:admin-support@thisisalice.com">contact support</ExternalLink>{' '}
      with any questions.
    </Typography>
  </Container>
)

export const AliceCardProgramSetupInfo = () => {
  return (
    <Grid container flexWrap="nowrap" spacing={2} justifyContent="space-between">
      <Grid item>
        <Typography variant="body2" gutterBottom>
          Complete the steps below to set up the account that will hold your employees&apos; pretax
          contributions.{' '}
          <Typography variant="body2" component="span" gutterBottom>
            <InfoDrawer
              anchorName="alice-card-setup-info"
              title={<Typography variant="h2">Contributions Account requirements</Typography>}
              infoContent={<InfoContent />}
              modal
            >
              Find out why we need this information
            </InfoDrawer>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  )
}
