import { Grid, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'

type ImageProps = {
  src: string
  alt?: string
  height?: number
}
export const SlideImage = ({ src, alt, height }: ImageProps) => (
  <img style={{ maxWidth: '100%', maxHeight: height || 300 }} src={src} alt={alt} />
)

type SlideProps = {
  isActive: boolean
  title: React.ReactNode
  children: React.ReactNode
  graphic: React.ReactNode
}
export const CarouselSlide = ({ title, children, graphic, isActive }: SlideProps) => (
  <Grid
    container
    className={isActive ? 'active-slide' : undefined}
    flexDirection="column"
    sx={{ paddingTop: 2 }}
    height="100%"
  >
    <Typography variant="h2" textAlign="center" gutterBottom>
      {title}
    </Typography>
    <Grid item>{children}</Grid>
    {graphic && (
      <Grid item flex={1} alignContent="center" textAlign="center">
        {graphic}
      </Grid>
    )}
  </Grid>
)
