import { Button, CircularProgress, Grid } from '@alice-financial/pretext-ui'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import UploadIcon from '@mui/icons-material/Upload'
import * as React from 'react'
import { CreateFormResponseInput, FormField, FormResponseOwnerTypeEnum } from '../../graphql/generated.types'
import { useOrgId } from '../../orgDashboard/useOrgId'
import { useCurrentOrgFormFields } from '../../organization/onboarding/survey/useOrgFormFields'
import { FormFieldUploadOwnerType, useFormFieldUpload } from '../api/useFormFieldUpload'
import { QuestionContainer, QuestionText } from '../surveyBlocks'
import { BasicQuestion, FormInputProps } from '../types'
import { fieldAnswer } from './formFieldUtils'

const FILE_CONTENT_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'application/rtf',
]

type FileUploadInputDisplayProps = Pick<
  CreateFormResponseInput,
  'ownerId' | 'ownerType' | 'formName' | 'formVersion'
> & {
  answer: FormField['answer']
  questionId: string
  questionText: string
  required: boolean
}
type OrgFileUploadInputDisplayConfigProps = {
  questionText: string
  questionId: string
  required: boolean
}

export const makeOrgFileUploadInputDisplayConfig = ({
  questionText,
  questionId,
  required,
}: OrgFileUploadInputDisplayConfigProps) => {
  const question = {
    questionText,
    questionId,
    required,
    getFieldValue: (answer) => answer || '',
    getAnswer: (values) => values[questionId] || '',
  } as const satisfies BasicQuestion<string>
  return {
    ...question,
    Input: ({ formName, formVersion }: Pick<FormInputProps, 'formName' | 'formVersion'>) => {
      const ownerId = useOrgId()
      const ownerType = FormResponseOwnerTypeEnum.Org
      const formFields = useCurrentOrgFormFields(formName, formVersion)
      const initialAnswer = fieldAnswer(formFields, questionId) || ''
      return (
        <FileUploadInputDisplay
          questionText={questionText}
          questionId={questionId}
          required={required}
          ownerId={ownerId}
          ownerType={ownerType}
          formName={formName}
          formVersion={formVersion}
          answer={initialAnswer}
        />
      )
    },
  }
}

const getOwnerTypeValue = (ownerType: FormResponseOwnerTypeEnum): FormFieldUploadOwnerType => {
  switch (ownerType) {
    case FormResponseOwnerTypeEnum.Org:
      return 'Employer::Organization'
  }
}

export const FileUploadInputDisplay = ({
  answer,
  ownerId,
  ownerType,
  questionText,
  questionId,
  formName,
  formVersion,
}: FileUploadInputDisplayProps) => {
  const [newFileUrl, setNewFileUrl] = React.useState<string | null>(null)
  const fileUrl = newFileUrl || answer
  const { mutate: uploadFile, isLoading: isUploading } = useFormFieldUpload({
    onSuccess: (response) => setNewFileUrl(response?.file.url),
  })

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0)
    if (file) {
      uploadFile({
        file,
        owner_id: ownerId,
        owner_type: getOwnerTypeValue(ownerType),
        question_id: questionId,
        form_name: formName,
        form_version: formVersion,
        question: questionText,
      })
    }
  }
  const inputId = `upload-file-${questionId}`
  return (
    <QuestionContainer>
      <QuestionText>{questionText}</QuestionText>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <input
            onChange={handleFileChange}
            accept={FILE_CONTENT_TYPES.join(',')}
            style={{ display: 'none' }}
            id={inputId}
            type="file"
          />
          <Button
            variant="contained"
            color="primary"
            component="label"
            htmlFor={inputId}
            sx={{ textWrap: 'nowrap' }}
            startIcon={fileUrl ? <SwapHorizIcon /> : <UploadIcon />}
            fullWidth
          >
            {fileUrl ? 'Replace file' : 'Upload file'}
          </Button>
        </Grid>
        {fileUrl && (
          <Grid item xs={4}>
            {isUploading ? (
              <CircularProgress />
            ) : (
              <Button component="a" href={fileUrl}>
                Download current file
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </QuestionContainer>
  )
}
