import { BoxProps, TextField } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { QuestionContainer, QuestionSubtext, QuestionText } from '../surveyBlocks'
import { TextQuestion } from '../types'

type TextConfigProps = {
  questionText: string
  questionSubtext?: string
  questionId: string
  placeholder?: string
  label?: string
  required: boolean
}
export const makeTextConfig = ({
  questionText,
  questionSubtext,
  questionId,
  placeholder,
  label,
  required,
}: TextConfigProps) => {
  const question = {
    questionText,
    questionSubtext,
    questionId,
    required,
    getFieldValue: (answer) => answer || '',
    getAnswer: (values) => values[questionId] || '',
  } as const satisfies TextQuestion
  return {
    ...question,
    Input: <T extends FieldValues>({ register }: { register: UseFormRegister<T> }) => (
      <TextQuestionDisplay
        question={question}
        register={register}
        placeholder={placeholder}
        label={label}
        required={required}
      />
    ),
  }
}

type TextQuestionDisplayProps<T extends FieldValues> = BoxProps & {
  question: TextQuestion
  register: UseFormRegister<T>
  placeholder?: string
  label?: string
  required?: boolean
}
export const TextQuestionDisplay = <T extends FieldValues>({
  question,
  register,
  placeholder,
  label,
  required,
  ...boxProps
}: TextQuestionDisplayProps<T>) => {
  return (
    <QuestionContainer {...boxProps}>
      {question.questionText && <QuestionText>{question.questionText}</QuestionText>}
      {question.questionSubtext && <QuestionSubtext>{question.questionSubtext}</QuestionSubtext>}
      <TextField
        fullWidth
        label={label}
        placeholder={placeholder}
        {...register(question.questionId as Path<T>)}
        helperText={required && 'required'}
      />
    </QuestionContainer>
  )
}
