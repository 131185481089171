import {
  Button,
  Container,
  Divider,
  Grid,
  InfoDrawer,
  List,
  ListItem,
  Typography,
  useInfoDrawerControl,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { isNotNull } from '../../../../utils/typeUtils'
import { useEmployeeQuery } from '../../../employeeDashboard/gql/employee_gen'
import { OrganizationDetailFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { useNotifyAdminKycMutation } from '../../homepage/gql/notifyAdminKYC_gen'
import { useOrgAdminsQuery } from '../../homepage/gql/orgAdmins_gen'
import { AddNewAdminForm } from './AddNewAdminForm'

const getIsNotSelf = (currentEmployeeId: number | undefined) => (admin: { id: number }) =>
  admin.id !== currentEmployeeId

type RequestKYCHelpFormProps = {
  org: OrganizationDetailFragment
  onSuccess: () => void
}
export const RequestKYCHelpForm = ({ org, onSuccess }: RequestKYCHelpFormProps) => {
  const { mutate: notifyAdminKyc } = useNotifyAdminKycMutation({ onSuccess })
  const { data: orgAdminsData } = useOrgAdminsQuery({ orgId: org.id })
  const { data: employeeData } = useEmployeeQuery()
  const currentEmployeeId = employeeData?.employee?.id
  const isNotSelf = getIsNotSelf(currentEmployeeId)

  const adminOptions =
    orgAdminsData?.organization?.employees.nodes
      ?.filter(isNotNull)
      .filter(isNotSelf)
      .map((admin) => ({
        adminId: admin.id,
        name: `${admin.user.firstName} ${admin.user.lastName}`,
      })) || []
  const hasAdminOptions = adminOptions.length > 0

  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom>
          If you are not an authorized Company Representative, or don&apos;t have all the information you need
          to complete this step, you can{' '}
          {hasAdminOptions ? 'select the administrator on your account or' : null} add a new administrator.
        </Typography>
      </Container>
      {hasAdminOptions && (
        <>
          <Container>
            <Typography variant="body2" fontWeight="bold">
              Select an administrator
            </Typography>
            <Grid container wrap="wrap" spacing={1}>
              <Grid item>
                <List>
                  {adminOptions.map(({ adminId, name }) => (
                    <ListItem key={adminId} divider>
                      <Grid container sx={{ margin: 0 }} wrap="nowrap" alignItems="center" columnGap={1}>
                        <Grid item flexGrow={1}>
                          <Typography variant="body2" color="primary" fontWeight="bold">
                            {name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => notifyAdminKyc({ adminId, orgId: org.id })}
                          >
                            Invite
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Container>
          <Divider sx={{ my: 2 }} />
        </>
      )}
      <Container sx={{ mb: 2 }}>
        <Typography variant="body2" fontWeight="bold" gutterBottom>
          Add a new administrator
        </Typography>
        <AddNewAdminForm org={org} onSuccess={onSuccess} />
      </Container>
    </>
  )
}

const ANCHOR_NAME = 'request-kyc-help'
export const RequestKYCHelpInfo = ({ org }: { org: OrganizationDetailFragment }) => {
  const { onClose } = useInfoDrawerControl(ANCHOR_NAME)
  return (
    <InfoDrawer
      anchorName={ANCHOR_NAME}
      infoContent={<RequestKYCHelpForm org={org} onSuccess={onClose} />}
      modal
      title={<Typography variant="h2">Invite your finance team to complete setup</Typography>}
      noIcon
    >
      <Typography gutterBottom color="inherit">
        Need help? Invite your finance team to complete this step
      </Typography>
    </InfoDrawer>
  )
}
