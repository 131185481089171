import {
  HealthcheckItem,
  HealthcheckItemProps,
  HealthcheckList,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../../../graphql/fragments/OrganizationFragment_gen'
import { FormNameEnum } from '../../../../graphql/generated.types'
import { useCurrentOrgRootPath, useOrg } from '../../../../orgDashboard/organization/useOrg'
import { PageBody } from '../../../../routes/PageBody'
import { QuestionPage } from '../../../../survey/types'
import { PagedOrgForm } from '../PagedOrgForm'
import { useOrgFormStatus } from '../useOrgFormFields'
import { existingEmployees } from './ExistingEmployeesInputs'
import { goalsInputs } from './GoalsInputs'
import { goalStory } from './GoalStory'
import { languages } from './LanguagesInputs'
import { newEmployees } from './NewEmployeesInputs'

/** * FORM CONFIGURATION ***/
const FORM_NAME = FormNameEnum.EnrollmentSuccess
const FORM_VERSION = 1
const PAGES: readonly QuestionPage[] = [languages, newEmployees, existingEmployees, goalsInputs, goalStory]
/** * END FORM CONFIGURATION ***/

const useEnrollmentSuccessFormStatus = (orgId: number) => useOrgFormStatus(orgId, FORM_NAME, FORM_VERSION)

export const EnrollmentSuccessPage = () => {
  const org = useOrg()
  if (!org) return null
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Setting up Enrollment Success</Typography>
      <HealthcheckList>
        <EnrollmentSuccessHealthcheck org={org} />
      </HealthcheckList>
    </PageBody>
  )
}

const BaseHealthcheck = ({
  step,
  disableDone,
  ...props
}: Pick<HealthcheckItemProps, 'primary' | 'secondary' | 'status' | 'todo'> & {
  step?: number
  disableDone?: boolean
}) => {
  const currentOrgRootPath = useCurrentOrgRootPath()
  const baseTargetPath = `${currentOrgRootPath}/enrollment-success`
  const targetPath = step ? `${baseTargetPath}?step=${step}` : baseTargetPath
  return (
    <HealthcheckItem to={targetPath} newRequirement {...props}>
      <PagedOrgForm formName={FORM_NAME} formVersion={FORM_VERSION} pages={PAGES} disableDone={disableDone} />
    </HealthcheckItem>
  )
}

type EnrollmentSuccessHealthcheckProps = {
  org: OrganizationDetailFragment
  todo?: boolean
}
export const EnrollmentSuccessHealthcheck = ({ org, todo }: EnrollmentSuccessHealthcheckProps) => {
  const formStatus = useEnrollmentSuccessFormStatus(org.id)
  if (formStatus !== 'complete') {
    return (
      <BaseHealthcheck
        status="incomplete"
        primary="Setting up enrollment success"
        secondary="Let us know how we can support your employees"
        todo={todo}
      />
    )
  }
  return (
    <BaseHealthcheck
      primary="Enrollment success survey complete"
      secondary="We will use your responses to support your employees better"
      status="complete"
    />
  )
}
