import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocaleState } from '../../../../translations/LocaleProvider'
import { getMonthFromDate } from '../../../../utils/dateUtils'
import { dateStringFormat } from '../../../../utils/formatters/dateFormat'
import { formatCentsAsDollars } from '../../../../utils/formatters/moneyFormat'
import { useBalanceInfoQuery } from '../../gql/balanceInfo_gen'
import { BalanceCalculationInfo } from './BalanceCalculationInfo'

export const BenefitsBalanceDisplay = () => {
  const { data: balanceInfoData } = useBalanceInfoQuery()
  const [language] = useLocaleState()
  const balanceInfo = balanceInfoData?.employee?.spendingConnectionInfo?.balanceInfo
  if (!balanceInfo) return null
  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h3" gutterBottom>
          <BalanceCalculationInfo>
            <FormattedMessage id="cards.alice.remaining_balance" />
          </BalanceCalculationInfo>
        </Typography>
        <Typography variant="body2" gutterBottom>
          <FormattedMessage id="cards.alice.save_up_to" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="cards.alice.so_far_this_month"
            values={{
              month: getMonthFromDate(language, balanceInfo.monthStart),
            }}
          />
          :
        </Typography>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <FormattedMessage id="cards.alice.spent" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="cards.alice.remaining" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={1}>
              <TableCell>
                <FormattedMessage id="benefits.mass_transit" />
              </TableCell>
              <TableCell>{formatCentsAsDollars(balanceInfo.massTransit.used)}</TableCell>
              <TableCell>{formatCentsAsDollars(balanceInfo.massTransit.remaining)}</TableCell>
            </TableRow>
            <TableRow key={2}>
              <TableCell>
                <FormattedMessage id="benefits.parking" />
              </TableCell>
              <TableCell>{formatCentsAsDollars(balanceInfo.parking.used)}</TableCell>
              <TableCell>{formatCentsAsDollars(balanceInfo.parking.remaining)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <Typography variant="body2">
          <FormattedMessage
            id="cards.alice.balance_resets_on"
            values={{
              date: dateStringFormat.long(balanceInfo.estimatedBalanceResetDate),
            }}
          />
        </Typography>
      </Box>
    </Paper>
  )
}
