import { ApiError } from '../responseErrors'
import { GqlInputError } from '../types'
import { GQL_PATH } from './config'

const formatInputErrorMessage = <TVariables extends Record<string, unknown> | void>(
  inputErrors: Array<GqlInputError<TVariables>>
) => inputErrors.map(({ message }) => message).join(', ')

// For mutations, the input variables are in the `input` field of the variables object
// For queries, the input variables are typically at the top level of the variables object
export class GqlInputErrorException<TVariables extends Record<string, unknown> | void> extends ApiError {
  inputErrors: Array<GqlInputError<TVariables>> = []
  constructor(inputErrors: Array<GqlInputError<TVariables>>) {
    super(formatInputErrorMessage(inputErrors), GQL_PATH)
    this.name = 'InputError'
    this.inputErrors = inputErrors
  }
}
