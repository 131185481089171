import {
  Alert,
  Box,
  Grid,
  HealthcheckItem,
  InertHealthcheckItemProps,
  SectionHeading,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { useCurrentOrgRootPath } from '../organization/useOrg'
import { useMayManageAliceCardProgram } from './aliceCardProgramUtils'
import { ConnectedPaymentMethods } from './ConnectedPaymentMethods'
import { ConnectPaymentMethodStep } from './ConnectPaymentMethodStep'
import { isOrgFundingConnected } from './funding/fundingUtils'
import { FundingAccountBalance } from './FundingAccountComponents'

const ItemDisplay = ({ subpath, ...props }: InertHealthcheckItemProps & { subpath?: string }) => {
  const orgRootPath = useCurrentOrgRootPath()
  const path = `${orgRootPath}/alice-card-program${subpath || ''}`
  return <HealthcheckItem {...props} to={path} />
}

type AliceCardProgramHealthcheckProps = {
  org?: OrganizationDetailFragment | null
  todo?: boolean
}
export const AliceCardProgramHealthcheck = ({ org, todo }: AliceCardProgramHealthcheckProps) => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  if (!mayManageAliceCardProgram) return null
  if (!org) return null
  const fundingConnected = isOrgFundingConnected(org)
  // const fundingActuallyConnected = org.aliceCardProgram.hasFundingConnected
  const hasUnifiedBanking = Boolean(org.aliceCardProgram.hasUnifiedBanking || org.employers.length === 1)

  return (
    <>
      {fundingConnected ? (
        <ItemDisplay
          todo={todo}
          status="complete"
          primary={hasUnifiedBanking ? 'Contributions Account connected' : 'Contributions Accounts connected'}
        >
          <AliceCardConnectionInfo org={org} />
        </ItemDisplay>
      ) : (
        <ItemDisplay
          todo={todo}
          status="incomplete"
          primary={todo ? 'Add Contributions Account' : 'Contributions Account not connected'}
          secondary="Account must be connected to fund pretax benefits"
          subpath="/account/setup"
        >
          <AliceCardConnectionInfo org={org} />
        </ItemDisplay>
      )}
    </>
  )
}

const AliceCardConnectionInfo = ({ org }: { org: OrganizationDetailFragment }) => {
  const currentOrgRootPath = useCurrentOrgRootPath()
  const isFundingConnected = isOrgFundingConnected(org)
  return (
    <>
      {isFundingConnected && (
        <Box mt={3} mb={4}>
          <SectionHeading
            to={`${currentOrgRootPath}/alice-card-program/account`}
            toLabel="See account activity"
          >
            Contributions Account
          </SectionHeading>
          <Grid container columnGap={2} wrap="nowrap" flexDirection="row-reverse" sx={{ marginTop: 2 }}>
            <Alert severity="info" icon={false} sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
              Employee spend on Alice Card is automatically transferred regularly from your payroll bank
              account to your contributions account. Transfers made to the contributions account{' '}
              <em>are not Alice&apos;s fees</em>, and the funds do not belong to Alice &ndash; they belong to
              your company&apos;s commuter benefit plan.
            </Alert>
            <Grid item minWidth="25%">
              <FundingAccountBalance org={org} />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box my={3}>
        <ConnectedPaymentMethods org={org} />
      </Box>
      {isFundingConnected && <ConnectPaymentMethodStep org={org} />}
    </>
  )
}
