import * as React from 'react'
import { makeLongTextConfig } from '../../../../survey/questionTypes/LongTextQuestionDisplay'
import { makeMultiSelectConfig } from '../../../../survey/questionTypes/MultiSelectQuestionDisplay'
import { QuestionPageDescription, QuestionPageHeading } from '../../../../survey/surveyBlocks'
import { FormInputProps } from '../../../../survey/types'

const LEARN_ABOUT_BENEFITS_LOCATION = makeLongTextConfig({
  questionText: 'Where do employees go to find benefits information?',
  questionId: 'learnAboutBenefitsLocation',
  placeholder: 'e.g., Employees check the “benefits” tab in our time tracking system.',
  required: true,
})
const LearnAboutBenefitsLocation = LEARN_ABOUT_BENEFITS_LOCATION.Input
const SHARE_IMPORTANT_INFORMATION_WITH_EMPLOYEES = makeMultiSelectConfig({
  questionText: 'How do you share important updates with employees?',
  questionId: 'shareImportantInformationWithEmployees',
  options: ['Send an email to all', 'Newsletter', 'Shift management system', 'Learning management system'],
  required: true,
})
const ShareImportantInformationWithEmployees = SHARE_IMPORTANT_INFORMATION_WITH_EMPLOYEES.Input
const QUESTIONS = [LEARN_ABOUT_BENEFITS_LOCATION, SHARE_IMPORTANT_INFORMATION_WITH_EMPLOYEES] as const

const ExistingEmployeesInputs = ({ register, control }: FormInputProps) => {
  return (
    <>
      <QuestionPageHeading>How existing employees learn about benefits.</QuestionPageHeading>
      <QuestionPageDescription>
        We want to ensure all existing employees get the right benefits information on launch day, so you stay
        compliant.
      </QuestionPageDescription>

      <LearnAboutBenefitsLocation register={register} />
      <ShareImportantInformationWithEmployees control={control} />
    </>
  )
}

export const existingEmployees = {
  Page: ExistingEmployeesInputs,
  questions: QUESTIONS,
}
