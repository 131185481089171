import { UseGqlMutationCallbacks } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../../utils/useMutationNotifier'
import {
  CreateFormResponseInput,
  FormNameEnum,
  FormResponseOwnerTypeEnum,
} from '../../../graphql/generated.types'
import {
  CreateFormResponseMutation,
  useCreateFormResponseMutation,
} from '../../../user/gql/createFormResponse_gen'

export type CreateFormResponseVariables = Pick<CreateFormResponseInput, 'formFields' | 'complete'>
export type UseCreateFormResponseMutationOptions = UseGqlMutationCallbacks<
  CreateFormResponseMutation,
  CreateFormResponseVariables
>

export type FormResponseConfig = {
  formName: FormNameEnum
  formVersion: number
  ownerId: number
  ownerType: FormResponseOwnerTypeEnum
}

export const useCreateFormResponse = (
  { formName, formVersion, ownerId, ownerType }: FormResponseConfig,
  mutationOptions?: UseCreateFormResponseMutationOptions
) => {
  const { mutateAsync: createFormResponse } = useCreateFormResponseMutation()

  const optionsWithNotification = useMutationNotifier({ onSuccess: false }, mutationOptions)
  return useMutation(
    ({ formFields, complete }) =>
      createFormResponse({
        input: {
          formFields,
          ownerId,
          ownerType,
          formName,
          formVersion,
          complete,
        },
      }),
    optionsWithNotification
  )
}
