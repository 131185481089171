import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { UpdateEmployeesInput } from '../../graphql/generated.types'
import { useOrgEmployeeListQuery } from './gql/orgEmployeeList_gen'
import { UpdateEmployeesMutation, useUpdateEmployeesMutation } from './gql/updateEmployees_gen'
import { useOrgId } from '../useOrgId'

type UseUpdateEmployeesMutationOptions = UseGqlMutationCallbacks<
  UpdateEmployeesMutation,
  Pick<UpdateEmployeesInput, 'accountStatus' | 'role'>
>
export const useUpdateEmployees = (
  employeeIds: Array<number>,
  mutationOptions?: UseUpdateEmployeesMutationOptions
) => {
  const optionsWithNotifs = useMutationNotifier({}, mutationOptions)
  const optionsWithInvalidation = useMutationOptionsWithInvalidation(optionsWithNotifs, [
    useOrgEmployeeListQuery.getKey({ orgId: useOrgId() }),
  ])
  const { mutateAsync } = useUpdateEmployeesMutation()
  return useMutation((attributes) => mutateAsync({ employeeIds, ...attributes }), optionsWithInvalidation)
}
