import { Carousel, useActiveSlide } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { CashBack } from './slides/CashBack'
import { Landing } from './slides/Landing'
import { Spend } from './slides/Spend'
import { Testimonials } from './slides/Testimonials'
import { TextUs } from './slides/TextUs'

/**
 * Compone that renders a carousel using MUI's Stepper component to cycle through
 * images and text describing how the product works. Each section will have a title,
 * description, and image.
 */
export const HowAliceWorks = ({
  disableNav,
  onComplete,
}: {
  onComplete?: () => void
  disableNav?: boolean
}) => {
  const [activeStep, setActiveStep] = useActiveSlide(disableNav)
  const numSteps = 5
  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === numSteps - 1
  const nextButtonLabelId = isFirstStep ? 'common.how_it_works' : isLastStep ? 'common.done' : 'common.next'
  const nextButtonLabel = <FormattedMessage id={nextButtonLabelId} />
  const backButtonLabelId = isFirstStep ? 'common.how_it_works' : 'common.back'
  const backButtonLabel = <FormattedMessage id={backButtonLabelId} />
  return (
    <Carousel
      nextButtonLabel={nextButtonLabel}
      backButtonLabel={backButtonLabel}
      onComplete={onComplete}
      disableNav={disableNav}
      onStepChange={setActiveStep}
    >
      <Landing isActive={activeStep === 0} />
      <Spend isActive={activeStep === 1} />
      <TextUs isActive={activeStep === 2} />
      <CashBack isActive={activeStep === 3} />
      <Testimonials isActive={activeStep === 4} />
    </Carousel>
  )
}
