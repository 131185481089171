import { Alert, HealthcheckItem, InertHealthcheckItemProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useParams } from 'react-router'
import { isNotNull } from '../../../utils/typeUtils'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { useCurrentOrgRootPath } from '../organization/useOrg'
import { getBillingProblem } from './billingUtils'
import { ConnectBilling } from './ConnectBilling'
import { OrgInvoices } from './OrgInvoices'
import { PaygroupBillingList } from './PaygroupBilling'

const ItemDisplay = (props: InertHealthcheckItemProps) => {
  const currentOrgRootPath = useCurrentOrgRootPath()
  const targetPath = `${currentOrgRootPath}/billing`
  return <HealthcheckItem {...props} to={targetPath} />
}

type BillingHealthcheckProps = {
  org?: OrganizationDetailFragment | null
  todo?: boolean
}

export const BillingHealthcheck = ({ org, todo }: BillingHealthcheckProps) => {
  if (!org) return null

  const billingProblem = getBillingProblem(org)
  if (!billingProblem) {
    return (
      <ItemDisplay
        status="complete"
        primary="Billing connected"
        secondary={org.billing?.hasUnifiedBilling ? 'Payment method is active' : 'Payment methods are active'}
        todo={todo}
      >
        <BillingInfo org={org} />
      </ItemDisplay>
    )
  }
  if (billingProblem === 'not_started') {
    return (
      <ItemDisplay
        status="incomplete"
        primary={todo ? 'Add Billing Methods' : 'Billing setup incomplete'}
        secondary="Configure and connect billing methods"
        todo={todo}
      >
        <BillingInfo org={org} />
      </ItemDisplay>
    )
  }
  if (billingProblem[0] === 'missing_source') {
    return (
      <ItemDisplay
        status="incomplete"
        primary={todo ? 'Add Billing Methods' : 'Billing setup incomplete'}
        secondary="Connect billing methods for remaining pay groups"
        todo={todo}
      >
        <BillingInfo org={org} />
      </ItemDisplay>
    )
  }
  if (billingProblem[0] === 'expired') {
    return (
      <ItemDisplay
        status="incomplete"
        primary="Billing card expired"
        secondary="Update expired payment card(s)"
        todo={todo}
      >
        <BillingInfo org={org} />
      </ItemDisplay>
    )
  }
  return null // shouldn't ever get here
}

const BillingInfo = ({ org }: { org: OrganizationDetailFragment }) => {
  const { employerId } = useParams()
  const hasBillingProblem = getBillingProblem(org)
  if (!org) return null
  return (
    <>
      {hasBillingProblem && (
        <Alert severity="warning" sx={{ mb: 3 }} icon={false}>
          Your company&apos;s billing information is missing or out of date. Alice will not process employee
          reimbursements without your updated billing information on file. Please update your billing
          information to avoid disruption to your services.
        </Alert>
      )}
      {employerId ? (
        <PaygroupBillingList
          employers={org.employers
            .filter(isNotNull)
            .filter((employer) => employer?.id.toString() === employerId)}
        />
      ) : (
        <ConnectBilling org={org} />
      )}
      <OrgInvoices org={org} />
    </>
  )
}
