import { fetchRest, UseRestMutationCallbacks } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { FormNameEnum } from '../../graphql/generated.types'

type FileUploadResponse = { file: { url: string; content_type: string; filename: string; size: number } }
export type FormFieldUploadOwnerType = 'Employer::Organization'
type FormFieldUploadSubmitValues = {
  form_name: FormNameEnum
  form_version: number
  file: File | null
  owner_id: number
  owner_type: FormFieldUploadOwnerType
  question_id: string
  question: string
}
type UseFormFieldUploadMutationOptions = UseRestMutationCallbacks<
  FileUploadResponse,
  FormFieldUploadSubmitValues
>

const uploadFile = (values: FormFieldUploadSubmitValues) => {
  let body: FormData | string
  const headers: HeadersInit = {}

  const { form_name, form_version, file, ...bodyValues } = values

  if (file === null) {
    body = JSON.stringify({ ...bodyValues, file: null })
    headers['Content-Type'] = 'application/json'
  } else {
    const formData = new FormData()
    formData.append('file', file)
    Object.entries(bodyValues).forEach(([key, value]) => {
      if (value !== null) {
        formData.append(key, value.toString())
      }
    })
    body = formData
    // No need to set Content-Type for FormData, browser will set it automatically
  }

  return fetchRest<FileUploadResponse>(`/api/form_responses/${form_name}/${form_version}`, {
    method: 'PATCH',
    body,
    headers,
  })
}

export const useFormFieldUpload = (mutationOptions: UseFormFieldUploadMutationOptions = {}) => {
  const mutationOptionsWithNotifier = useMutationNotifier({ onMutate: 'Uploading file...' }, mutationOptions)
  return useMutation(uploadFile, mutationOptionsWithNotifier)
}
