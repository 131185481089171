import { FormHelperText, Grid } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { isNotNull } from '../../../utils/typeUtils'
import { QuestionContainer, QuestionSubtext, QuestionText } from '../surveyBlocks'
import { TextQuestion } from '../types'
import { TextQuestionDisplay } from './TextQuestionDisplay'

type UserInputsConfigProps = {
  questionText: string
  questionSubtext?: string
  questionId: string
  name: true // assumed/required, but prop makes it explicit
  email: true // assumed/required, but prop makes it explicit
  phone?: boolean
  company?: boolean
  required: boolean
}
type UserInputField = 'name' | 'email' | 'phone' | 'company'
export const makeUserInputsConfig = ({
  questionText,
  questionSubtext,
  questionId,
  phone,
  company,
  required,
}: UserInputsConfigProps) => {
  const fields: Array<UserInputField> = ['name', 'email']
  if (phone) fields.push('phone')
  if (company) fields.push('company')

  const questions = fields.reduce(
    (acc, field) => {
      const fieldQuestionId = `${questionId}${field}`
      acc[field] = {
        questionText: '',
        questionId: fieldQuestionId,
        required,
        getFieldValue: (answer) => answer || '',
        getAnswer: (values) => values[fieldQuestionId] || '',
      } as const satisfies TextQuestion
      return acc
    },
    {} as Record<UserInputField, TextQuestion>
  )
  return {
    questions: Object.values(questions),
    Input: <T extends FieldValues>({ register }: { register: UseFormRegister<T> }) => (
      <QuestionContainer>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={12}>
            <QuestionText>{questionText}</QuestionText>
            {questionSubtext && <QuestionSubtext>{questionSubtext}</QuestionSubtext>}
          </Grid>
          {[
            { question: questions.name, label: 'Name' },
            { question: questions.email, label: 'Email' },
            questions.phone && { question: questions.phone, label: 'Phone (mobile)' },
            questions.company && { question: questions.company, label: 'Company' },
          ]
            .filter(isNotNull)
            .map(({ question, label }) => (
              <Grid item xs={9} key={question.questionId}>
                <TextQuestionDisplay question={question} register={register} label={label} mb={0} />
              </Grid>
            ))}
        </Grid>
        {required && <FormHelperText>required</FormHelperText>}
      </QuestionContainer>
    ),
  }
}
