import * as React from 'react'
import { makeUserInputsConfig } from '../../../../survey/questionTypes/UserInputsDisplay'
import { QuestionPageDescription } from '../../../../survey/surveyBlocks'
import { FormInputProps, Question } from '../../../../survey/types'

const PAYROLL_MANAGER_QUESTIONS = makeUserInputsConfig({
  questionText: 'Payroll Manager',
  questionSubtext: 'The person who presses submit to run payroll each pay period.',
  questionId: 'payrollManager',
  name: true,
  email: true,
  phone: true,
  required: true,
})
const PayrollManagerInputs = PAYROLL_MANAGER_QUESTIONS.Input
const BOOKKEEPER_QUESTIONS = makeUserInputsConfig({
  questionText: 'Bookkeeper',
  questionSubtext: 'The person who maintains accurate financial records using a system like QuickBooks.',
  questionId: 'bookkeeper',
  name: true,
  email: true,
  phone: true,
  required: true,
})
const BookkeeperInputs = BOOKKEEPER_QUESTIONS.Input
const ACCOUNTANT_QUESTIONS = makeUserInputsConfig({
  questionText: 'Accountant/Finance Leader',
  questionSubtext: 'The person who prepares and reviews your monthly financial statements.',
  questionId: 'accountant',
  name: true,
  email: true,
  phone: true,
  required: true,
})
const AccountantInputs = ACCOUNTANT_QUESTIONS.Input
const CONTROLLER_QUESTIONS = makeUserInputsConfig({
  questionText: 'Controller',
  questionSubtext: 'The person who pays invoices and approves bank transfers.',
  questionId: 'controller',
  name: true,
  email: true,
  phone: true,
  required: true,
})
const ControllerInputs = CONTROLLER_QUESTIONS.Input
const BROKER_CONSULTANT_QUESTIONS = makeUserInputsConfig({
  questionText: 'Benefits Broker/Consultant',
  questionSubtext: 'The person who supports your team and helps with benefits strategy.',
  questionId: 'brokerConsultant',
  name: true,
  email: true,
  phone: true,
  required: false,
})
const BrokerConsultantInputs = BROKER_CONSULTANT_QUESTIONS.Input
const HEAD_OF_PEOPLE_QUESTIONS = makeUserInputsConfig({
  questionText: 'Head of People/HR',
  questionSubtext: 'The person who decides what payroll system your company uses.',
  questionId: 'headOfPeople',
  name: true,
  email: true,
  phone: true,
  required: true,
})
const HeadOfPeopleInputs = HEAD_OF_PEOPLE_QUESTIONS.Input

const QUESTIONS: Array<Question> = [
  ...PAYROLL_MANAGER_QUESTIONS.questions,
  ...BOOKKEEPER_QUESTIONS.questions,
  ...ACCOUNTANT_QUESTIONS.questions,
  ...CONTROLLER_QUESTIONS.questions,
  ...BROKER_CONSULTANT_QUESTIONS.questions,
  ...HEAD_OF_PEOPLE_QUESTIONS.questions,
]

const AdminRoleInputs = ({ register }: FormInputProps) => {
  return (
    <>
      <QuestionPageDescription>
        We&apos;ll set up a few main roles now so they have the right permissions.
      </QuestionPageDescription>
      <QuestionPageDescription>
        If one person covers multiple roles, no worries &ndash; just enter their details in each required
        section. You can always add or edit admins later.
      </QuestionPageDescription>

      <PayrollManagerInputs register={register} />
      <BookkeeperInputs register={register} />
      <AccountantInputs register={register} />
      <ControllerInputs register={register} />
      <BrokerConsultantInputs register={register} />
      <HeadOfPeopleInputs register={register} />
    </>
  )
}

export const adminRoles = {
  Page: AdminRoleInputs,
  questions: QUESTIONS,
}
