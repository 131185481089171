import {
  alpha,
  Box,
  BoxProps,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemLink,
  ListItemText,
  Paper,
  PaperProps,
  Skeleton,
  Typography,
  TypographyProps,
  useTheme,
} from '@alice-financial/pretext-ui'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import WarningIcon from '@mui/icons-material/Warning'
import * as React from 'react'
import { useLocation } from 'react-router'

export type HealthcheckItemStatus = 'complete' | 'incomplete' | 'pending' | 'disabled'
export type InertHealthcheckItemProps = {
  status: HealthcheckItemStatus
  primary: string
  secondary?: string
  icon?: string | React.ReactNode
  action?: React.ReactNode
  todo?: boolean
  newRequirement?: boolean // indicates new feature, no hard expectation that it's complete
  children?: React.ReactNode
}
export type HealthcheckItemProps = InertHealthcheckItemProps & {
  to: string
  isLoading?: boolean
  actionIcon?: React.ReactNode
}
const StatusIcon = ({
  status,
  todo,
  newRequirement,
}: Pick<HealthcheckItemProps, 'status' | 'todo' | 'newRequirement'>) => {
  if (status === 'complete') return <CheckBoxIcon color="primary" fontSize="large" />
  if (status === 'incomplete') {
    return todo ? (
      <CheckBoxOutlineBlankOutlinedIcon color="primary" fontSize="large" />
    ) : newRequirement ? (
      <Typography
        fontSize="12px"
        fontWeight="bold"
        lineHeight={1}
        padding="8px 2px"
        sx={(theme) => ({
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.main,
          borderRadius: 1,
        })}
      >
        NEW
      </Typography>
    ) : (
      <WarningIcon color="warning" fontSize="large" />
    )
  }
  if (status === 'pending') {
    return <IndeterminateCheckBoxOutlinedIcon color="primary" fontSize="large" />
  }
  if (status === 'disabled') {
    return <CheckBoxOutlineBlankOutlinedIcon color="disabled" fontSize="large" />
  }
  return null
}

const StatusIconOverride = ({ icon }: Pick<HealthcheckItemProps, 'icon'>) => {
  if (!icon) return null
  if (typeof icon === 'string') {
    return (
      <Typography fontSize="2em" lineHeight={1}>
        {icon}
      </Typography>
    )
  }
  return <>{icon}</>
}

export const StatusAvatar = ({
  icon,
  status,
  todo,
  newRequirement,
}: Pick<HealthcheckItemProps, 'status' | 'icon' | 'todo' | 'newRequirement'>) => (
  <ListItemIcon>
    {icon ? (
      <StatusIconOverride icon={icon} />
    ) : (
      <StatusIcon status={status} todo={todo} newRequirement={newRequirement} />
    )}
  </ListItemIcon>
)

type HealthcheckTypographyProps<TElement extends React.ElementType = 'span'> = Omit<
  TypographyProps<TElement>,
  'variant' | 'fontWeight' | 'component'
>
const HealthcheckPrimary = (props: HealthcheckTypographyProps) => (
  <Typography variant="body2" fontWeight="bold" {...props} />
)
const HealthcheckSecondary = (props: HealthcheckTypographyProps<'p'>) => (
  <Typography variant="caption" component="p" {...props} />
)

export const HealthcheckItem = ({
  to,
  status: _status,
  primary,
  secondary,
  isLoading,
  icon,
  action,
  actionIcon,
  todo,
  newRequirement,
  children,
  ...props
}: HealthcheckItemProps) => {
  const statusToUse = isLoading ? 'pending' : _status
  const statusColor = useStatusColor(statusToUse, { todo, newRequirement })
  const theme = useTheme()

  const isOnLocation = useLocation().pathname === new URL(to, window.location.origin).pathname
  const ActionSurface = isOnLocation ? Box : ListItemLink
  // when the item is active, add a left border and reduce the padding to compensate layout
  const accent = isOnLocation
    ? { borderLeft: `4px solid ${theme.palette.primary.main}`, paddingLeft: 0.5 }
    : undefined

  return (
    <ListItem
      component={todo ? Paper : 'li'}
      divider
      disableGutters={!isOnLocation}
      disablePadding={!isOnLocation}
      secondaryAction={action}
      sx={{ flexDirection: 'column', ...accent }}
      {...props}
    >
      <ActionSurface
        to={to}
        color={statusColor ? alpha(statusColor, 1) : undefined}
        actionIcon={actionIcon}
        sx={{ width: '100%' }}
      >
        <Grid container spacing={1} width="100%">
          <Grid item>
            <StatusAvatar icon={icon} status={statusToUse} todo={todo} newRequirement={newRequirement} />
          </Grid>
          <Grid item>
            <ListItemText
              primary={
                isLoading ? <Skeleton width="20ch" /> : <HealthcheckPrimary>{primary}</HealthcheckPrimary>
              }
              secondary={
                isLoading && secondary ? (
                  <Skeleton width="30ch" />
                ) : (
                  <HealthcheckSecondary>{secondary}</HealthcheckSecondary>
                )
              }
              sx={{ margin: secondary ? 0 : undefined }}
            />
          </Grid>
        </Grid>
      </ActionSurface>
      {children && (
        <Collapse in={isOnLocation} sx={{ width: '100%' }}>
          <Box p={2} pl={6} mt={1}>
            {isOnLocation && children}
          </Box>
        </Collapse>
      )}
    </ListItem>
  )
}

const useStatusColor = (
  status: HealthcheckItemStatus,
  { todo, newRequirement }: Pick<HealthcheckItemProps, 'todo' | 'newRequirement'>
) => {
  const theme = useTheme()
  const incompleteColor =
    todo || newRequirement ? undefined : alpha(theme.palette.warning.main, theme.palette.action.hoverOpacity)
  const statusColors = {
    complete: undefined,
    incomplete: incompleteColor,
    pending: incompleteColor,
    disabled: alpha(theme.palette.text.disabled, theme.palette.action.hoverOpacity),
  }
  return statusColors[status]
}

/**
 * Use when you need a list item with a status avatar, but don't want it to be a link
 */
export const InertHealthcheckItem = ({
  primary,
  secondary,
  icon,
  status,
  action,
  todo,
  newRequirement,
}: InertHealthcheckItemProps) => {
  const statusColor = useStatusColor(status, { todo, newRequirement })

  return (
    <ListItem
      component={todo ? Paper : 'li'}
      divider
      alignItems="center"
      sx={{ backgroundColor: statusColor }}
    >
      <ListItemIcon>
        <StatusAvatar icon={icon} status={status} todo={todo} newRequirement={newRequirement} />
      </ListItemIcon>

      <ListItemText
        primary={<HealthcheckPrimary>{primary}</HealthcheckPrimary>}
        secondary={<HealthcheckSecondary>{secondary}</HealthcheckSecondary>}
      />

      {action && <ListItemText sx={{ flex: '0 0 auto' }} secondary={action} />}
    </ListItem>
  )
}

const PaperWrapper = (props: PaperProps) => <Paper elevation={1} {...props} />
type HealthcheckListProps = {
  todo?: boolean
} & BoxProps
export const HealthcheckList = ({ children, todo, ...props }: HealthcheckListProps) => {
  return (
    <Box component={todo ? 'div' : PaperWrapper} {...props}>
      <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: todo ? 2 : 0 }}>
        {children}
      </List>
    </Box>
  )
}
