import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type OrderFormAcceptanceQueryVariables = Types.Exact<{
  ownerId: Types.Scalars['Int']['input']
  accepterId: Types.Scalars['Int']['input']
}>

export type OrderFormAcceptanceQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: number
    contractSignedOn?: string | null
    acceptance?: {
      __typename?: 'Acceptance'
      withdrawnAt?: string | null
      createdAt: string
      acceptedAt?: string | null
      formUrl?: string | null
      signedDocument?: { __typename?: 'File'; url?: string | null } | null
    } | null
    onboardingState: { __typename?: 'OnboardingState'; isAlreadyLaunched: boolean }
  } | null
}

export type OrderFormAcceptanceFragment = {
  __typename?: 'Acceptance'
  withdrawnAt?: string | null
  createdAt: string
  acceptedAt?: string | null
  formUrl?: string | null
  signedDocument?: { __typename?: 'File'; url?: string | null } | null
}

export const OrderFormAcceptanceFragmentDoc = `
    fragment OrderFormAcceptance on Acceptance {
  withdrawnAt
  createdAt
  acceptedAt
  signedDocument {
    url
  }
  formUrl
}
    `
export const OrderFormAcceptanceDocument = `
    query OrderFormAcceptance($ownerId: Int!, $accepterId: Int!) {
  organization(id: $ownerId) {
    id
    acceptance(agreementType: pth_order_form, accepterId: $accepterId) {
      ...OrderFormAcceptance
    }
    contractSignedOn
    onboardingState {
      isAlreadyLaunched
    }
  }
}
    ${OrderFormAcceptanceFragmentDoc}`

export const useOrderFormAcceptanceQuery = <TData = OrderFormAcceptanceQuery, TError = Error>(
  variables: OrderFormAcceptanceQueryVariables,
  options?: UseQueryOptions<OrderFormAcceptanceQuery, TError, TData>
) => {
  return useQuery<OrderFormAcceptanceQuery, TError, TData>(
    ['OrderFormAcceptance', variables],
    fetchGql<OrderFormAcceptanceQuery, OrderFormAcceptanceQueryVariables>(
      OrderFormAcceptanceDocument,
      variables
    ),
    options
  )
}

useOrderFormAcceptanceQuery.getKey = (variables: OrderFormAcceptanceQueryVariables) => [
  'OrderFormAcceptance',
  variables,
]
