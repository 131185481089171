import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateFormResponseMutationVariables = Types.Exact<{
  input: Types.CreateFormResponseInput
}>

export type CreateFormResponseMutation = {
  __typename?: 'Mutation'
  createFormResponse?: {
    __typename?: 'CreateFormResponsePayload'
    response?: { __typename?: 'Response'; id: number } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreateFormResponseDocument = `
    mutation CreateFormResponse($input: CreateFormResponseInput!) {
  createFormResponse(input: $input) {
    response {
      id
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreateFormResponseMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateFormResponseMutation,
    TError,
    CreateFormResponseMutationVariables,
    TContext
  >
) => {
  return useMutation<CreateFormResponseMutation, TError, CreateFormResponseMutationVariables, TContext>(
    ['CreateFormResponse'],
    (variables?: CreateFormResponseMutationVariables) =>
      fetchGql<CreateFormResponseMutation, CreateFormResponseMutationVariables>(
        CreateFormResponseDocument,
        variables
      )(),
    options
  )
}
