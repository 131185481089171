import { Checkbox, CheckboxController, InlineInputController, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Control } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { StartDateInfo } from './StartDateInfo'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'
import { CompleteEnrollmentValues } from './useCompleteEnrollmentRequirements'

type EmploymentStartDateInputControllerProps = {
  control: Control<CompleteEnrollmentValues>
  alreadyStartedState: [boolean, (value: boolean) => void]
}

/**
 * This is an input that will set `emplo
 */
export const EmploymentStartDateInputController = ({
  control,
  alreadyStartedState: [alreadyStarted, setAlreadyStarted],
}: EmploymentStartDateInputControllerProps) => {
  const intl = useIntl()
  const { data: enrollmentData } = useEmployeeEnrollmentInfoQuery()
  const employee = enrollmentData?.employee
  const knownStartDate = employee?.employmentStartDate
  const employerName = employee?.paygroupInfo.marketingFriendlyName

  // not allowed to re-set start date
  if (knownStartDate) return null

  return (
    <>
      <CheckboxController
        color="primary"
        name="alreadyStarted"
        control={control}
        label={
          <Typography variant="body2">
            <FormattedMessage
              id="enroll.register.i_have_started_to_work_at_employer"
              values={{ employerName }}
            />
          </Typography>
        }
      />

      <Checkbox
        name="not_started"
        onClick={() => setAlreadyStarted(false)}
        checked={!alreadyStarted}
        color="primary"
        label={
          <>
            <Typography variant="body2" gutterBottom={!alreadyStarted}>
              {alreadyStarted ? (
                <FormattedMessage id="enroll.register.i_have_not_started" values={{ employerName }} />
              ) : (
                <FormattedMessage
                  id="enroll.register.start_date_input"
                  values={{
                    employerName,
                    'start-date-input': () => (
                      <InlineInputController
                        name="employmentStartDate"
                        control={control}
                        type="date"
                        rules={{
                          required: intl.formatMessage({
                            id: 'enroll.register.start_date.validation.required',
                          }),
                        }}
                      />
                    ),
                  }}
                />
              )}
            </Typography>
            {!alreadyStarted && (
              <Typography>
                <StartDateInfo employerName={employerName}>
                  <FormattedMessage id="enroll.register.dont_know_start_date_caption" />
                </StartDateInfo>
              </Typography>
            )}
          </>
        }
      />
    </>
  )
}
