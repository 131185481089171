import * as React from 'react'
import { makeOrgFileUploadInputDisplayConfig } from '../../../../survey/questionTypes/FileUploadInputDisplay'
import { makeTextConfig } from '../../../../survey/questionTypes/TextQuestionDisplay'
import { QuestionPageDescription, QuestionPageHeading } from '../../../../survey/surveyBlocks'
import { FormInputProps } from '../../../../survey/types'

const NEW_HIRE_PACKET_FILE_UPLOAD = makeOrgFileUploadInputDisplayConfig({
  questionText: 'If you have a paper packet for new hires, upload it here.',
  questionId: 'newHirePacket',
  required: false,
})
const NewHirePacketFileUpload = NEW_HIRE_PACKET_FILE_UPLOAD.Input
const NEW_HIRE_PACKET_URL = makeTextConfig({
  questionText: 'If you have an online packet for new hires, please provide the URL.',
  questionId: 'newHirePacketUrl',
  placeholder: 'https://',
  required: false,
})
const NewHirePacketUrl = NEW_HIRE_PACKET_URL.Input

const QUESTIONS = [NEW_HIRE_PACKET_FILE_UPLOAD, NEW_HIRE_PACKET_URL] as const

const NewEmployeesInputs = ({ register, formName, formVersion }: FormInputProps) => {
  return (
    <>
      <QuestionPageHeading>How new hires learn about benefits</QuestionPageHeading>
      <QuestionPageDescription>
        We want to ensure every new hire gets the right benefits information from day one, so you stay
        compliant.
      </QuestionPageDescription>

      <NewHirePacketFileUpload formName={formName} formVersion={formVersion} />
      <NewHirePacketUrl register={register} />
    </>
  )
}

export const newEmployees = {
  Page: NewEmployeesInputs,
  questions: QUESTIONS,
}
