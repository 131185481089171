import { HealthcheckList, Typography, useActiveSlide } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { payrollConnection } from '../../organization/onboarding/survey/payrollOps/PayrollConnectionInputs'
import {
  PayrollHealthcheck,
  usePayrollOpsSurveyPages,
} from '../../organization/onboarding/survey/payrollOps/PayrollHealthcheck'
import { payrollProcess } from '../../organization/onboarding/survey/payrollOps/PayrollProcessInputs'
import { PageBody } from '../../routes/PageBody'
import { useOrg } from '../organization/useOrg'

/**
 * Hook to set the active slide to the most-actionable slide _if one is not already set_
 * 1. If survey is not complete, go to first page of survey - basic location
 * 2. Otherwise go to work location page
 */
const useSetPreferredSlide = ({ org }: { org: OrganizationDetailFragment }) => {
  const onboardingState = org.onboardingState
  const pages = usePayrollOpsSurveyPages()
  const [_, setActiveStep] = useActiveSlide()
  const [searchParams] = useSearchParams()
  React.useEffect(() => {
    if (searchParams.has('step')) return
    const preferredStep = onboardingState.isDoneWithPayrollInstructions ? payrollProcess : payrollConnection
    setActiveStep(pages.indexOf(preferredStep), { replace: true })
  }, [onboardingState, pages, setActiveStep, searchParams])
}

const SetPreferredSlide = ({ org }: { org: OrganizationDetailFragment }) => {
  useSetPreferredSlide({ org })
  return null
}

export const PayrollPage = () => {
  const org = useOrg()
  if (!org) return null
  return (
    <PageBody maxWidth={800}>
      <SetPreferredSlide org={org} />
      <Typography variant="h1" gutterBottom>
        Payroll
      </Typography>
      <HealthcheckList>
        <PayrollHealthcheck org={org} />
      </HealthcheckList>
    </PageBody>
  )
}
