import * as React from 'react'
import { makeLongTextConfig } from '../../../../survey/questionTypes/LongTextQuestionDisplay'
import { QuestionPageDescription } from '../../../../survey/surveyBlocks'
import { FormInputProps } from '../../../../survey/types'

const LEADERSHIP_STORY = makeLongTextConfig({
  questionText:
    'In 3 months, what update do you want to share with leadership about how Alice made things better?',
  questionId: 'leadershipStory',
  required: true,
})

const PEOPLE_GOAL = makeLongTextConfig({
  questionText: 'What big goals do you have around team culture, recruiting, or retention?',
  questionId: 'peopleGoal',
  placeholder: 'We want to increase the number of employees who accept our offer of employment.',
  required: true,
})

const QUESTIONS = [LEADERSHIP_STORY, PEOPLE_GOAL] as const

const GoalStory = ({ register }: FormInputProps) => {
  return (
    <>
      <QuestionPageDescription>Help us understand what success looks like for you.</QuestionPageDescription>
      {QUESTIONS.map(({ questionId, Input }) => (
        <Input key={questionId} register={register} />
      ))}
    </>
  )
}

export const goalStory = {
  Page: GoalStory,
  questions: QUESTIONS,
}
