import {
  Chip,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemLink,
  ListItemText,
  Toolbar,
  Typography,
} from '@alice-financial/pretext-ui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'
import { useLocation, useParams } from 'react-router'
import { useEmployeeQuery } from '../employeeDashboard/gql/employee_gen'
import { useMayManageAliceCardProgram } from './aliceCardProgram/aliceCardProgramUtils'
import { useCurrentOrgRootPath, useOrg } from './organization/useOrg'

type NavLinkProps = {
  to: string
  isNew?: boolean
  children: React.ReactNode
}
const NavLink = ({ to, isNew, children }: NavLinkProps) => {
  const orgRootPath = useCurrentOrgRootPath()
  const location = useLocation()
  const target = `${orgRootPath}${to}`
  const isOnTarget = location.pathname === target
  if (isOnTarget) {
    return (
      <ListItem sx={(theme) => ({ p: theme.spacing(0.85, 1), alignItems: 'center', columnGap: 0.5 })}>
        <ListItemText
          disableTypography
          primary={
            <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
              <Typography color="text.disabled" fontWeight="bold">
                {children}
              </Typography>
            </Grid>
          }
        />
        <ChevronRightIcon color="disabled" />
      </ListItem>
    )
  }
  return (
    <ListItem disablePadding disableGutters>
      <ListItemLink to={target} sx={{ columnGap: 0 }}>
        <ListItemText
          disableTypography
          primary={
            <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
              <Typography color="primary" fontWeight="bold">
                {children}
              </Typography>

              {isNew && <Chip label="New" color="primary" size="small" sx={{ marginLeft: 1 }} />}
            </Grid>
          }
        />
      </ListItemLink>
    </ListItem>
  )
}
type NavigationProps = {
  drawerWidth: number
}

type L = (props: { root?: string }) => React.ReactElement | null

const OrgDashboard = () => <NavLink to="">Org dashboard</NavLink>

const CompanyProfile = () => <NavLink to="/company-profile">Company profile</NavLink>
const PayrollOps = () => <NavLink to="/payroll">Payroll</NavLink>

const OrgBilling = () => <NavLink to="/billing">Billing</NavLink>
const AliceCardProgram = () => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  if (!mayManageAliceCardProgram) return null
  return <NavLink to="/alice-card-program">Contributions Account</NavLink>
}
const EnrollmentSuccess = () => <NavLink to="/enrollment-success">Enrollment success</NavLink>
const EmployerDashBoard: L = ({ root = '' }) => <NavLink to={root}>Paygroup home</NavLink>
const People: L = ({ root = '' }) => {
  const org = useOrg()
  if (!org?.onboardingState.isPayrollConnectionComplete) return null
  return <NavLink to={`${root}/people`}>People</NavLink>
}
const PayPeriods: L = ({ root = '' }) => {
  const org = useOrg()
  if (!org?.onboardingState.isAlreadyLaunched) return null // won't have pay periods until we start processing benefits
  return <NavLink to={`${root}/pay-periods`}>Pay periods</NavLink>
}
const Documents: L = ({ root = '' }) => <NavLink to={`${root}/documents`}>Documents</NavLink>
const Locations: L = () => <NavLink to="/locations">Locations &amp; Pay Groups</NavLink>
const Profile: L = () => <NavLink to={`/profile`}>Email preferences</NavLink>

/**
 * Payroll reports are only relevant for particular payroll platforms that
 * are not sufficiently automated. Only display nav when the platform permits
 */
const PayrollReports: L = () => {
  const org = useOrg()
  if (!org?.payrollConnection.payrollPlatform?.permitsAdminReports) return null

  return <NavLink to="/payroll-reports">Payroll reports</NavLink>
}

const Reports: L = () => <NavLink to="/reports">Reports</NavLink>

const EmployerNavigationList = ({ routingRoot }: { routingRoot: string }) => {
  const { data: employeeData } = useEmployeeQuery()
  const employee = employeeData?.employee
  if (!employee) return null

  return (
    <>
      <List disablePadding dense>
        <EmployerDashBoard root={routingRoot} />
        <PayPeriods root={routingRoot} />
        <People root={routingRoot} />
        <Documents root={routingRoot} />
      </List>
      <Divider sx={{ my: 1 }} />
      <List disablePadding dense>
        <OrgDashboard />
        <Profile />
      </List>
    </>
  )
}
const OrgNavigationList = () => {
  return (
    <List disablePadding dense>
      <PayrollOps />
      <CompanyProfile />
      <Locations />
      <AliceCardProgram />
      <PayPeriods />
      <PayrollReports />
      <Reports />
      <OrgBilling />
      <EnrollmentSuccess />
      <People />
      <Documents />
      <Profile />
    </List>
  )
}

export const OrgDashboardNavigation = ({ drawerWidth }: NavigationProps) => {
  const params = useParams()

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      {params.employerId ? (
        <EmployerNavigationList routingRoot={`/${params.employerId}`} />
      ) : (
        <OrgNavigationList />
      )}
    </Drawer>
  )
}
