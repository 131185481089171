import * as Types from '../../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type OrgFormResponseQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input']
  formName: Types.FormNameEnum
  formVersion: Types.Scalars['Int']['input']
}>

export type OrgFormResponseQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: number
    formResponse?: {
      __typename?: 'Response'
      id: number
      complete: boolean
      formFields: Array<{ __typename?: 'FormField'; question: string; questionId: string; answer: string }>
    } | null
  } | null
}

export type FormFieldFragment = {
  __typename?: 'FormField'
  question: string
  questionId: string
  answer: string
}

export const FormFieldFragmentDoc = `
    fragment FormField on FormField {
  question
  questionId
  answer
}
    `
export const OrgFormResponseDocument = `
    query OrgFormResponse($id: Int!, $formName: FormNameEnum!, $formVersion: Int!) {
  organization(id: $id) {
    id
    formResponse(formName: $formName, formVersion: $formVersion) {
      id
      formFields {
        ...FormField
      }
      complete
    }
  }
}
    ${FormFieldFragmentDoc}`

export const useOrgFormResponseQuery = <TData = OrgFormResponseQuery, TError = Error>(
  variables: OrgFormResponseQueryVariables,
  options?: UseQueryOptions<OrgFormResponseQuery, TError, TData>
) => {
  return useQuery<OrgFormResponseQuery, TError, TData>(
    ['OrgFormResponse', variables],
    fetchGql<OrgFormResponseQuery, OrgFormResponseQueryVariables>(OrgFormResponseDocument, variables),
    options
  )
}

useOrgFormResponseQuery.getKey = (variables: OrgFormResponseQueryVariables) => ['OrgFormResponse', variables]
