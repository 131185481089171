import { HealthcheckList, Typography, useActiveSlide } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { basicLocationInputs } from '../../organization/onboarding/survey/locations/BasicLocationsInputs'
import { useLocationsFormStatus } from '../../organization/onboarding/survey/locations/config'
import {
  LocationsSurveyHealthcheck,
  useLocationsSurveyPages,
} from '../../organization/onboarding/survey/locations/LocationsSurvey'
import { PageBody } from '../../routes/PageBody'
import { useOrg } from '../organization/useOrg'
import { workLocationInputs } from './WorkLocationList'

/**
 * Hook to set the active slide to the most-actionable slide _if one is not already set_
 * 1. If survey is not complete, go to first page of survey - basic location
 * 2. Otherwise go to work location page
 */
const useSetPreferredSlide = ({ org }: { org: OrganizationDetailFragment }) => {
  const pages = useLocationsSurveyPages()
  const formStatus = useLocationsFormStatus(org.id)
  const [_, setActiveStep] = useActiveSlide()
  const [searchParams] = useSearchParams()
  React.useEffect(() => {
    if (formStatus === undefined || searchParams.has('step')) return
    const preferredStep = formStatus === 'complete' ? workLocationInputs : basicLocationInputs
    setActiveStep(pages.indexOf(preferredStep), { replace: true })
  }, [formStatus, pages, setActiveStep, searchParams])
}

const SetPreferredSlide = ({ org }: { org: OrganizationDetailFragment }) => {
  useSetPreferredSlide({ org })
  return null
}

export const WorkLocationsPage = () => {
  const org = useOrg()
  if (!org) return null
  return (
    <PageBody maxWidth={800}>
      <SetPreferredSlide org={org} />
      <Typography variant="h1">Locations &amp; Pay Groups</Typography>
      <HealthcheckList>
        <LocationsSurveyHealthcheck org={org} />
      </HealthcheckList>
    </PageBody>
  )
}
