import * as React from 'react'
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  SxProps,
  Theme,
} from '@mui/material'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

type CheckboxControllerProps<T extends FieldValues> = Omit<CheckboxProps, 'name'> &
  Pick<ControllerProps<T>, 'name' | 'control' | 'rules'> &
  Pick<FormControlLabelProps, 'label'>
export const CheckboxController = <T extends FieldValues>({
  control,
  name,
  rules,
  label,
  ...props
}: CheckboxControllerProps<T>) => (
  <Controller
    render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
      const isError = Boolean((isTouched || submitCount > 0) && error?.message)
      return (
        <Checkbox
          {...props}
          {...field}
          label={label}
          error={error}
          checked={field.value}
          color={isError ? 'error' : 'primary'}
        />
      )
    }}
    control={control}
    name={name}
    rules={rules}
  />
)

export type CheckboxProps = MuiCheckboxProps & {
  labelStyles?: SxProps<Theme>
  label: React.ReactNode
  error?: { message?: string | undefined } | undefined
}
export const Checkbox = ({ label, error, labelStyles, ...props }: CheckboxProps) => (
  <FormControl fullWidth error={Boolean(error)}>
    <FormControlLabel
      control={<MuiCheckbox {...props} color={error ? 'error' : 'primary'} />}
      label={
        <>
          {label}
          {error && <FormHelperText>{error?.message}</FormHelperText>}
        </>
      }
      sx={labelStyles}
    />
  </FormControl>
)
