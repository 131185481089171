import { HealthcheckItem, HealthcheckItemProps, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../../../graphql/fragments/OrganizationFragment_gen'
import { FormNameEnum } from '../../../../graphql/generated.types'
import { useCurrentOrgRootPath } from '../../../../orgDashboard/organization/useOrg'
import { PageBody } from '../../../../routes/PageBody'
import { QuestionPage } from '../../../../survey/types'
import { PagedOrgForm } from '../PagedOrgForm'
import { useOrgFormStatus } from '../useOrgFormFields'
import { payrollProcess } from './PayrollProcessInputs'
import { terminationProcess } from './TerminationProcessInputs'
import { payrollConnection } from './PayrollConnectionInputs'

/** * FORM CONFIGURATION ***/
const FORM_NAME = FormNameEnum.PayrollOps
const FORM_VERSION = 1
const PAGES: readonly QuestionPage[] = [payrollConnection, payrollProcess, terminationProcess]
/** * END FORM CONFIGURATION ***/

export const usePayrollOpsSurveyPages = () => PAGES
const usePayrollOpsFormStatus = (orgId: number) => useOrgFormStatus(orgId, FORM_NAME, FORM_VERSION)
export const PayrollOpsSurveyPage = () => {
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Payroll operations</Typography>
    </PageBody>
  )
}

const BaseHealthcheck = (
  props: Pick<HealthcheckItemProps, 'primary' | 'secondary' | 'status' | 'todo'> & { step?: number }
) => {
  const currentOrgRootPath = useCurrentOrgRootPath()
  const baseTargetPath = `${currentOrgRootPath}/payroll`
  const targetPath = props.step ? `${baseTargetPath}?step=${props.step}` : baseTargetPath
  return (
    <HealthcheckItem to={targetPath} newRequirement {...props}>
      <PagedOrgForm
        formName={FORM_NAME}
        formVersion={FORM_VERSION}
        pages={PAGES}
        onCompleteReturnTo={props.todo ? 'payroll' : undefined}
      />
    </HealthcheckItem>
  )
}

type PayrollOpsSurveyHealthcheckProps = {
  org: OrganizationDetailFragment
  todo?: boolean
}
export const PayrollHealthcheck = ({ org, todo }: PayrollOpsSurveyHealthcheckProps) => {
  const onboardingState = org.onboardingState
  const isSurveyComplete = usePayrollOpsFormStatus(org.id) === 'complete'
  const isComplete = isSurveyComplete && onboardingState.isPayrollConnected
  const isPending = !isComplete && (onboardingState.isDoneWithPayrollInstructions || isSurveyComplete)
  const statusMessage = !onboardingState.isDoneWithPayrollInstructions
    ? 'Connect your payroll platform'
    : !isSurveyComplete
      ? 'Complete your payroll operations survey'
      : !onboardingState.isPayrollConnected
        ? 'Our team is currently reviewing your payroll information'
        : 'Payroll is connected'
  const primary = todo ? 'Payroll' : statusMessage
  const secondary = todo ? statusMessage : undefined
  const step = onboardingState.isDoneWithPayrollInstructions ? PAGES.indexOf(payrollProcess) : undefined

  return (
    <BaseHealthcheck
      primary={primary}
      secondary={secondary}
      status={isComplete ? 'complete' : isPending ? 'pending' : 'incomplete'}
      todo={todo}
      step={step}
    />
  )
}
