import * as React from 'react'
import { makeLongTextConfig } from '../../../../survey/questionTypes/LongTextQuestionDisplay'
import { QuestionPageDescription } from '../../../../survey/surveyBlocks'
import { FormInputProps } from '../../../../survey/types'
import { Typography } from '@alice-financial/pretext-ui'

const VOLUNTARY_TERMINATION_PROCESS = makeLongTextConfig({
  questionText: 'Tell us about what happens when an employee quits.',
  questionSubtext:
    'How is the payroll manager notified? When is employment status updated in payroll? Are final paychecks processed as on-cycle or off-cycle?',
  questionId: 'voluntaryTerminationProcess',
  required: false,
})
const VoluntaryTerminationProcess = VOLUNTARY_TERMINATION_PROCESS.Input

const INVOLUNTARY_TERMINATION_PROCESS = makeLongTextConfig({
  questionText: 'Tell us about what happens when an employee is let go.',
  questionSubtext:
    'How is the payroll manager notified? When is employment status updated in payroll? Are final paychecks processed as on-cycle or off-cycle?',
  questionId: 'involuntaryTerminationProcess',
  required: false,
})
const InvoluntaryTerminationProcess = INVOLUNTARY_TERMINATION_PROCESS.Input

const QUESTIONS = [VOLUNTARY_TERMINATION_PROCESS, INVOLUNTARY_TERMINATION_PROCESS] as const

const TerminationProcessInputs = ({ register }: FormInputProps) => {
  return (
    <>
      <QuestionPageDescription>
        Understanding how your team handles terminations keeps your payroll and benefits running smoothly.
      </QuestionPageDescription>
      <Typography variant="body2">Alice needs to know about terminations in order to:</Typography>
      <Typography
        component="ul"
        variant="body2"
        gutterBottom
        sx={{ pl: `1.5em !important`, listStyleType: 'disc' }}
      >
        <li>make sure final checks are accurate</li>
        <li>ensure terminated employees don&apos;t continue using benefits</li>
      </Typography>

      <Typography variant="body2" gutterBottom>
        Please tell us about how your company does employee terminations
      </Typography>

      <VoluntaryTerminationProcess register={register} />
      <InvoluntaryTerminationProcess register={register} />
    </>
  )
}

export const terminationProcess = {
  Page: TerminationProcessInputs,
  questions: QUESTIONS,
}
