import * as Types from '../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type BalanceInfoQueryVariables = Types.Exact<{ [key: string]: never }>

export type BalanceInfoQuery = {
  __typename?: 'Query'
  employee?: {
    __typename?: 'Employee'
    spendingConnectionInfo: {
      __typename?: 'SpendingConnectionInfo'
      balanceInfo: {
        __typename?: 'BalanceInfo'
        monthStart: string
        estimatedBalanceResetDate: string
        massTransit: { __typename?: 'Balance'; used: number; remaining: number }
        parking: { __typename?: 'Balance'; used: number; remaining: number }
      }
    }
  } | null
}

export type BalanceInfoFragment = {
  __typename?: 'BalanceInfo'
  monthStart: string
  estimatedBalanceResetDate: string
  massTransit: { __typename?: 'Balance'; used: number; remaining: number }
  parking: { __typename?: 'Balance'; used: number; remaining: number }
}

export const BalanceInfoFragmentDoc = `
    fragment BalanceInfo on BalanceInfo {
  monthStart
  estimatedBalanceResetDate
  massTransit {
    used
    remaining
  }
  parking {
    used
    remaining
  }
}
    `
export const BalanceInfoDocument = `
    query BalanceInfo {
  employee {
    spendingConnectionInfo {
      balanceInfo {
        ...BalanceInfo
      }
    }
  }
}
    ${BalanceInfoFragmentDoc}`

export const useBalanceInfoQuery = <TData = BalanceInfoQuery, TError = Error>(
  variables?: BalanceInfoQueryVariables,
  options?: UseQueryOptions<BalanceInfoQuery, TError, TData>
) => {
  return useQuery<BalanceInfoQuery, TError, TData>(
    variables === undefined ? ['BalanceInfo'] : ['BalanceInfo', variables],
    fetchGql<BalanceInfoQuery, BalanceInfoQueryVariables>(BalanceInfoDocument, variables),
    options
  )
}

useBalanceInfoQuery.getKey = (variables?: BalanceInfoQueryVariables) =>
  variables === undefined ? ['BalanceInfo'] : ['BalanceInfo', variables]
