import { useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { FormResponseOwnerTypeEnum } from '../../../graphql/generated.types'
import { useOrgId } from '../../../orgDashboard/useOrgId'
import { useOrgFormResponseQuery } from './gql/orgFormResponse_gen'
import {
  FormResponseConfig,
  useCreateFormResponse,
  UseCreateFormResponseMutationOptions,
} from './useCreateFormResponse'

type OrgFormResponseConfig = Pick<FormResponseConfig, 'formName' | 'formVersion'>

export const useCreateOrgFormResponse = (
  { formName, formVersion }: OrgFormResponseConfig,
  mutationOptions?: UseCreateFormResponseMutationOptions
) => {
  const orgId = useOrgId()
  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(mutationOptions, [
    useOrgFormResponseQuery.getKey({ id: orgId, formName, formVersion }),
  ])
  return useCreateFormResponse(
    { formName, formVersion, ownerId: orgId, ownerType: FormResponseOwnerTypeEnum.Org },
    mutationOptionsWithInvalidation
  )
}
